import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchCalendarProfileAPI = async (token: string) => {
  const response = await axios.get(`${API_URL}/v1/admin/calendar-x/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

export const saveCalendarProfileAPI = async (
  profileData: {
    fullName: string;
    email: string;
    about: string;
    location: string;
    image: string;
  },
  token: string,
) => {
  const response = await axios.post(
    `${API_URL}/v1/admin/calendar-x/profile`,
    profileData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data.data;
};

export const updateCalendarProfileAPI = async (
  profileData: {
    fullName: string;
    email: string;
    about: string;
    location: string;
    image: string;
  },
  token: string,
) => {
  const response = await axios.patch(
    `${API_URL}/v1/admin/calendar-x/profile`,
    profileData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data.data;
};

export const uploadProfileImageAPI = async (file: File, token: string) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await axios.put(
    `${API_URL}/v1/admin/calendar-x/profile-image`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response.data.data.fileName;
};
