import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LoadingScreen from "../LoadingScreen";
import { ArrowLeft, Clock, Plus, Trash } from "react-feather";
import CustomSelect from "../CustomSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from "date-fns";

const { REACT_APP_API_URL } = process.env;

interface ITimeSlot {
  startTime: number;
  endTime: number;
}

interface IDay {
  day: number;
  timeslots: ITimeSlot[];
}

interface IAvailabilityDetail {
  _id: string;
  title: string;
  timezone: string;
  days: IDay[];
  calendarXUserId: string;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
}

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const timezones = [
  "Asia/Kolkata",
  "America/New_York",
  "Europe/London",
  "Australia/Sydney",
  "Asia/Tokyo",
  "America/Los_Angeles",
  "Europe/Berlin",
];

const AvailabilityDetail: React.FC = () => {
  const navigate = useNavigate();
  const { availabilityScheduleId } = useParams<{
    availabilityScheduleId: string;
  }>();
  const [availability, setAvailability] = useState<IAvailabilityDetail | null>(
    null,
  );
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const token = useSelector((state: RootState) => state.adminAuth.token);
  const [isOpen, setIsOpen] = useState(false);
  const titleInputRef = useRef<HTMLInputElement | null>(null);

  const formattedTimezones = timezones.map((tz) => ({ _id: tz, title: tz }));

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_URL}/v1/admin/calendar-x/availability/${availabilityScheduleId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setAvailability(response.data.data);
        setNewTitle(response.data.data.title);
      } catch (error) {
        console.error("Failed to fetch availability details", error);
      }
    };
    fetchAvailability();
  }, [availabilityScheduleId, token]);

  const formatTime = (minutes: number) => {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    const period = h >= 12 ? "PM" : "AM";
    const hour = h % 12 === 0 ? 12 : h % 12;
    const minute = m < 10 ? `0${m}` : m;
    return `${hour}:${minute} ${period}`;
  };

  const groupTimeslots = (days: IDay[]) => {
    const grouped: { [key: string]: string[] } = {};

    days.forEach((day) => {
      day.timeslots.forEach((slot) => {
        const timeRange = `${formatTime(slot.startTime)} - ${formatTime(slot.endTime)}`;
        if (grouped[timeRange]) {
          grouped[timeRange].push(dayNames[day.day]);
        } else {
          grouped[timeRange] = [dayNames[day.day]];
        }
      });
    });

    return Object.keys(grouped)
      .map((timeRange) => {
        const days = grouped[timeRange];
        const formattedDays =
          days.length > 1 ? `${days[0]} - ${days[days.length - 1]}` : days[0];
        return `${formattedDays}, ${timeRange}`;
      })
      .join("\n");
  };

  useEffect(() => {
    if (availability) {
      const summary = groupTimeslots(availability.days);
      document.getElementById("availability-summary")!.innerHTML =
        summary.replace(/\n/g, "<br />");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availability]);

  const handleTitleEdit = () => {
    setIsEditingTitle(true);
    setTimeout(() => {
      titleInputRef.current?.focus();
    }, 100);
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
    if (availability) {
      setAvailability({ ...availability, title: newTitle });
    }
  };

  const handleTitleSave = async () => {
    if (!availability) return;

    try {
      const response = await axios.patch(
        `${REACT_APP_API_URL}/v1/admin/calendar-x/availability/${availability._id}`,
        {
          title: newTitle,
          days: availability.days,
          timezone: availability.timezone,
          isDefault: availability.isDefault,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.success) {
        setAvailability({ ...availability, title: newTitle });
        setIsEditingTitle(false);
        navigate(`/dashboard/calendarX/availability`);
      }
    } catch (error) {
      console.error("Failed to update availability", error);
    }
  };

  const handleTimeChange = (
    dayIndex: number,
    slotIndex: number,
    type: "start" | "end",
    value: Date,
  ) => {
    if (!availability) return;
    const timeValue = value.getHours() * 60 + value.getMinutes();
    const newDays = [...availability.days];
    newDays[dayIndex].timeslots[slotIndex][
      type === "start" ? "startTime" : "endTime"
    ] = timeValue;
    setAvailability({ ...availability, days: newDays });
  };

  const addTimeSlot = (dayIndex: number) => {
    if (!availability) return;
    const newDays = [...availability.days];
    newDays[dayIndex].timeslots.push({ startTime: 540, endTime: 1080 });
    setAvailability({ ...availability, days: newDays });
  };

  const removeTimeSlot = (dayIndex: number, slotIndex: number) => {
    if (!availability) return;
    const newDays = [...availability.days];
    newDays[dayIndex].timeslots.splice(slotIndex, 1);
    setAvailability({ ...availability, days: newDays });
  };

  const toggleDefault = async () => {
    if (!availability || availability.isDefault) return;

    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/v1/admin/calendar-x/availability-default/${availability._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.success) {
        setAvailability({
          ...availability,
          isDefault: !availability.isDefault,
        });
      }
    } catch (error) {
      console.error("Failed to toggle default status", error);
    }
  };

  const handleDelete = async () => {
    if (!availability) return;

    try {
      const response = await axios.delete(
        `${REACT_APP_API_URL}/v1/admin/calendar-x/availability/${availability._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.success) {
        navigate("/dashboard/calendarX/availability");
      }
    } catch (error) {
      console.error("Failed to delete availability", error);
    }
  };

  if (!availability) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingScreen />
      </div>
    );
  }

  return (
    <div className="flex h-screen flex-col p-6 font-wix text-white">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <button onClick={() => navigate(-1)} className="text-lg">
            <ArrowLeft />
          </button>
          <div>
            {isEditingTitle ? (
              <input
                ref={titleInputRef}
                type="text"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                onBlur={handleTitleBlur}
                className="rounded bg-secondary p-2 text-white"
              />
            ) : (
              <h1 className="text-2xl font-semibold">
                {availability.title}
                <button
                  onClick={handleTitleEdit}
                  className="ml-2 text-sm font-medium text-[#ffffffa8] hover:text-white"
                >
                  Edit
                </button>
              </h1>
            )}
            <div
              id="availability-summary"
              className="whitespace-pre-line"
            ></div>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <label className="mr-2 text-sm font-medium text-gray-200">
            Set to Default
          </label>
          <button
            onClick={toggleDefault}
            className={`${availability.isDefault ? "bg-[#e6e7e6]" : "bg-[#404040]"} 
              relative inline-flex h-6 w-11 items-center rounded-full focus:outline-none`}
          >
            <span
              className={`${availability.isDefault ? "translate-x-6" : "translate-x-1"} 
                inline-block h-4 w-4 transform rounded-full bg-primary`}
            />
          </button>
          <button
            onClick={() => setShowDeletePopup(true)}
            className="rounded bg-primary px-4 py-2 text-light hover:opacity-80"
          >
            Delete
          </button>
          <button
            onClick={handleTitleSave}
            className="rounded bg-light px-4 py-2 font-semibold text-primary hover:opacity-80"
          >
            Save
          </button>
        </div>
      </div>
      <div className="my-3">
        <label className="mb-3 block text-lg font-medium text-gray-200">
          Timezone
        </label>
        <CustomSelect
          name="timezone"
          value={availability.timezone}
          onChange={(value) =>
            setAvailability({ ...availability, timezone: value })
          }
          options={formattedTimezones}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          placeholder="Select timezone"
        />
        <p className="mb-1 mt-6 text-lg">Set Availability</p>
      </div>
      <div className="flex-2 scrollbar space-y-2 overflow-y-auto">
        {availability.days.map((day, dayIndex) => (
          <div key={day.day} className="rounded bg-secondary p-4">
            <div className="mb-2 flex items-center justify-between">
              <label className="flex items-center">
                <button
                  onClick={() => {
                    const newDays = [...availability.days];
                    if (day.timeslots.length > 0) {
                      newDays[dayIndex].timeslots = [];
                    } else {
                      newDays[dayIndex].timeslots.push({
                        startTime: 540,
                        endTime: 1080,
                      });
                    }
                    setAvailability({ ...availability, days: newDays });
                  }}
                  className={`${day.timeslots.length > 0 ? "bg-[#e6e7e6]" : "bg-[#404040]"} 
                    relative inline-flex h-6 w-11 items-center rounded-full focus:outline-none`}
                >
                  <span
                    className={`${day.timeslots.length > 0 ? "translate-x-6" : "translate-x-1"} 
                      inline-block h-4 w-4 transform rounded-full bg-primary`}
                  />
                </button>
                <span className="ml-3 text-[17px] text-light">
                  {dayNames[day.day]}
                </span>
              </label>
            </div>
            {day.timeslots.map((slot, slotIndex) => (
              <div key={slotIndex} className="mb-2 mt-4 flex items-center">
                <div className="flex items-center rounded-md border border-primary bg-[#404040] pr-2">
                  <DatePicker
                    selected={setHours(
                      setMinutes(new Date(), slot.startTime % 60),
                      Math.floor(slot.startTime / 60),
                    )}
                    onChange={(date: Date) =>
                      handleTimeChange(dayIndex, slotIndex, "start", date)
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="form-input w-20 bg-[#404040] px-2 py-1 text-light outline-none"
                  />{" "}
                  <Clock className="h-4 w-4" />
                </div>
                <span className="mx-2 text-light">-</span>
                <div className="mr-4 flex items-center rounded-md border border-primary bg-[#404040] pr-2">
                  <DatePicker
                    selected={setHours(
                      setMinutes(new Date(), slot.endTime % 60),
                      Math.floor(slot.endTime / 60),
                    )}
                    onChange={(date: Date) =>
                      handleTimeChange(dayIndex, slotIndex, "end", date)
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="form-input w-20 bg-[#404040] px-2 py-1 text-light outline-none"
                  />
                  <Clock className="h-4 w-4" />
                </div>
                <button
                  onClick={() => addTimeSlot(dayIndex)}
                  className="mr-2 rounded border border-[#ffffff3d] bg-light px-3 py-2 text-primary hover:opacity-80"
                >
                  <Plus className="h-4 w-4" />
                </button>
                <button
                  onClick={() => removeTimeSlot(dayIndex, slotIndex)}
                  className="rounded border border-[#ffffff3d] bg-[#404040] px-3 py-2 text-light hover:opacity-80"
                >
                  <Trash className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        ))}
      </div>
      {showDeletePopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded bg-secondary p-6 shadow-lg">
            <h2 className="mb-4 text-lg font-semibold">
              Are you sure you want to delete this availability? This action
              cannot be undone.
            </h2>
            <div className="flex justify-end">
              <button
                className="rounded bg-red-500 px-3 py-1 text-white transition hover:bg-red-600"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="ml-2 rounded bg-gray-500 px-3 py-1 text-white transition hover:bg-gray-600"
                onClick={() => setShowDeletePopup(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailabilityDetail;
