import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAllPsychologists,
  deletePsychologist,
} from "../../api/psychologistUsersApi";
import { RootState } from "../store";

interface PsychologistState {
  psychologists: Array<any>;
  loading: boolean;
  error: string | null;
}

const initialState: PsychologistState = {
  psychologists: [],
  loading: false,
  error: null,
};

export const getPsychologists = createAsyncThunk(
  "psychologists/getAll",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    return await fetchAllPsychologists(token);
  },
);

export const removePsychologist = createAsyncThunk(
  "psychologists/delete",
  async (psychologistId: string, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    await deletePsychologist(psychologistId, token);
    dispatch(getPsychologists());
  },
);

const psychologistSlice = createSlice({
  name: "psychologists",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPsychologists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPsychologists.fulfilled, (state, action) => {
        state.loading = false;
        state.psychologists = action.payload;
      })
      .addCase(getPsychologists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(removePsychologist.pending, (state) => {
        state.loading = true;
      })
      .addCase(removePsychologist.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(removePsychologist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default psychologistSlice.reducer;
