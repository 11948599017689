import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineCalendar, AiOutlineCheck } from "react-icons/ai";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Calendar, Clock } from "react-feather";
import { BsPeople } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../LoadingScreen";

const { REACT_APP_API_URL } = process.env;

interface Booking {
  _id: string;
  date: string;
  startTime: number;
  endTime: number;
  notes: string;
  status: string;
  client: {
    name: string;
    email: string;
    userId: string;
  };
  meta: {
    provider: string;
    code: string | null;
  };
}

const Bookings: React.FC = () => {
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(true);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [filter, setFilter] = useState<string>("Pending");
  const token = useSelector((state: RootState) => state.adminAuth.token);

  const fetchBookings = async (status: string) => {
    try {
      setLoadingData(true);
      const response = await axios.get(
        `${REACT_APP_API_URL}/v1/admin/calendar-x/booking?status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setBookings(response.data.data);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchBookings(filter.toLowerCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const formatTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins < 10 ? "0" : ""}${mins}`;
  };

  const handleStatus = async (bookingId: string, status: string) => {
    if (!bookings) return;

    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/v1/admin/calendar-x/booking/${bookingId}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data) {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      console.error("Error saving event details:", error);
    }
  };

  return (
    <div>
      <div className="flex h-[63px] items-center gap-x-4 px-6 text-white text-opacity-90">
        <button onClick={() => navigate(-1)} className="text-lg">
          <FaArrowLeft />
        </button>
        <h1 className="text-2xl font-semibold">Bookings</h1>
      </div>{" "}
      <div className="border-b border-primary"></div>
      <div className="p-6">
        <p className="mb-6">
          See upcoming and past events booked through your event type links.
        </p>
        <div className="mb-4 flex">
          {["Pending", "Confirmed", "Request-Reschedule", "Canceled"].map(
            (status) => (
              <button
                key={status}
                className={`mr-2 px-4 py-2 ${filter === status ? "bg-light text-primary" : "bg-primary text-light"} rounded`}
                onClick={() => setFilter(status)}
              >
                {status}
              </button>
            ),
          )}
        </div>
        {loadingData ? (
          <LoadingScreen />
        ) : (
          <div className="space-y-4">
            {bookings.length > 0 ? (
              bookings.map((booking) => (
                <div
                  key={booking._id}
                  className="mt-6 flex items-center justify-between rounded bg-secondary p-4"
                >
                  <div>
                    <div className="flex items-center gap-x-2">
                      <p className="flex items-center gap-x-2 text-lg font-bold">
                        <Calendar className="h-4 w-4" />
                        {new Date(booking.date).toLocaleDateString()}
                      </p>
                    </div>
                    <p className="text-md my-1 flex items-center gap-x-1 text-light">
                      <Clock className="h-4 w-4" />
                      {formatTime(booking.startTime)} -{" "}
                      {formatTime(booking.endTime)}
                    </p>
                    <p className="text-md my-1 text-light">{booking.notes}</p>
                    <p className="my-1 flex items-center gap-x-1 text-sm text-light">
                      <BsPeople />
                      You and {booking.client.name}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    {booking.status !== "request-reschedule" && (
                      <button
                        className="flex items-center rounded bg-light px-4 py-2 font-medium text-primary"
                        onClick={() =>
                          handleStatus(booking._id, "request-reschedule")
                        }
                      >
                        <AiOutlineCalendar />
                        <span className="ml-2">Request Schedule</span>
                      </button>
                    )}
                    {booking.status === "pending" && (
                      <button
                        className="flex items-center rounded bg-blue-600 p-2"
                        onClick={() => handleStatus(booking._id, "confirmed")}
                      >
                        <AiOutlineCheck />
                        <span className="ml-2">Confirm</span>
                      </button>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p className="mt-10 text-center text-light">
                No bookings available for this status.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Bookings;
