import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const fetchAllPsychologists = async (token: string) => {
  const response = await axios.get(
    `${REACT_APP_API_URL}/v1/admin/psychologist/all`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data.data;
};

export const deletePsychologist = async (
  psychologistId: string,
  token: string,
) => {
  await axios.delete(
    `${REACT_APP_API_URL}/v1/admin/psychologist/${psychologistId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
