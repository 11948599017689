import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const loginApi = async (credentials: {
  email: string;
  password: string;
}) => {
  const response = await axios.post(`${API_URL}/v1/admin/login`, credentials);
  return response.data.data.accessToken;
};

export const fetchProfileApi = async (token: string) => {
  const response = await axios.get(`${API_URL}/v1/admin/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

export const resetPasswordApi = async (newPassword: string, token: string) => {
  const response = await axios.post(
    `${API_URL}/v1/admin/reset-password`,
    { newPassword },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};
