import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUsersApi = async (token: string) => {
  const response = await axios.get(`${API_URL}/v1/admin/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

export const addUserApi = async (
  user: { fullName: string; email: string; password: string; role: string },
  token: string,
) => {
  const response = await axios.post(`${API_URL}/v1/admin/add-user`, user, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

export const updateUserRoleApi = async (
  userId: string,
  role: string,
  token: string,
) => {
  const response = await axios.post(
    `${API_URL}/v1/admin/roles/${userId}`,
    { role },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data.data;
};

export const deleteUserApi = async (userId: string, token: string) => {
  const response = await axios.delete(`${API_URL}/v1/admin/delete/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return { _id: userId };
};

export const activateUserApi = async (userId: string, token: string) => {
  const response = await axios.post(
    `${API_URL}/v1/admin/activate/${userId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data.data;
};

export const disableUserApi = async (userId: string, token: string) => {
  const response = await axios.post(
    `${API_URL}/v1/admin/disable/${userId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data.data;
};
