import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { Clock, List, Video } from "react-feather";
import CustomSelect from "../CustomSelect";
import LoadingScreen from "../LoadingScreen";

const { REACT_APP_API_URL } = process.env;

interface EventType {
  _id: string;
  title: string;
  description: string;
  durationInMinutes: number;
  provider: string;
  calendarXUserAvailabilityId: string;
  createdAt: string;
  updatedAt: string;
}

interface Availability {
  _id: string;
  title: string;
}

const EventDetails: React.FC = () => {
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(true);
  const { eventId } = useParams<{ eventId: string }>();
  const [eventType, setEventType] = useState<EventType | null>(null);
  const [initialEventType, setInitialEventType] = useState<EventType | null>(
    null,
  );
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const token = useSelector((state: RootState) => state.adminAuth.token);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_URL}/v1/admin/calendar-x/event-type/${eventId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setLoadingData(false);
        setEventType(response.data.data);
        setInitialEventType(response.data.data);
      } catch (error) {
        console.error("Error fetching event details:", error);
        navigate("/dashboard/calendarX/events");
      }
    };

    const fetchAvailabilities = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_URL}/v1/admin/calendar-x/availability`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setAvailabilities(response.data.data);
      } catch (error) {
        console.error("Error fetching availabilities:", error);
        navigate("/dashboard/calendarX/events");
      }
    };

    fetchEventDetails();
    fetchAvailabilities();
  }, [eventId, navigate, token]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setEventType(initialEventType);
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
    if (!eventType) return;

    try {
      await axios.patch(
        `${REACT_APP_API_URL}/v1/admin/calendar-x/event-type/${eventId}`,
        { calendarXUserAvailabilityId: eventType.calendarXUserAvailabilityId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setIsEditing(false);
      setInitialEventType(eventType);
    } catch (error) {
      console.error("Error saving event details:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!eventType) return;

    setEventType({
      ...eventType,
      calendarXUserAvailabilityId: e.target.value,
    });
  };

  if (loadingData) {
    return (
      <p className="mt-32">
        <LoadingScreen />
      </p>
    );
  }

  if (!eventType) return <></>;

  return (
    <div className="min-h-screen text-white">
      <div className="flex h-[63px] items-center gap-x-4 px-6">
        <button onClick={() => navigate(-1)} className="text-lg">
          <FaArrowLeft />
        </button>
        <h1 className="text-2xl font-semibold">Event Types</h1>
      </div>
      <div className="border-b border-primary"></div>
      <div className="py-6 sm:px-6 lg:px-6">
        <div className="space-y-6 rounded-lg bg-secondary p-6 shadow-md">
          <div>
            <div className="flex items-center justify-between">
              <h2 className="mb-4 text-xl font-semibold">{eventType.title}</h2>

              <div className="flex justify-end space-x-2 font-medium">
                {isEditing && (
                  <button
                    onClick={handleCancelClick}
                    className="rounded-md bg-primary px-4 py-2 text-light hover:opacity-80"
                  >
                    Cancel
                  </button>
                )}
                <button
                  onClick={isEditing ? handleSaveClick : handleEditClick}
                  className="rounded-md bg-light px-4 py-2 text-primary hover:opacity-80"
                >
                  {isEditing ? "Save" : "Edit"}
                </button>
              </div>
            </div>

            <p className="flex items-center gap-x-2 text-light">
              <span className="flex items-center gap-x-2 font-medium">
                <Clock className="h-4 w-4" /> Duration :
              </span>
              {eventType.durationInMinutes} mins
            </p>
            <p className="my-1.5 flex items-center gap-x-2 text-light">
              <span className="flex items-center gap-x-2 font-medium">
                <List className="h-4 w-4" /> Description :
              </span>
              {eventType.description}
            </p>
            <p className="flex items-center gap-x-2 text-light">
              <span className="flex items-center gap-x-2 font-medium">
                <Video className="h-4 w-4" /> Provider :
              </span>
              {eventType.provider}
            </p>
          </div>
          <div>
            <h2 className="mb-2 text-xl font-semibold">Availability</h2>
            {isEditing ? (
              <CustomSelect
                name="calendarXUserAvailabilityId"
                value={eventType.calendarXUserAvailabilityId}
                onChange={(value) =>
                  handleInputChange({
                    target: { value },
                  } as React.ChangeEvent<HTMLSelectElement>)
                }
                options={availabilities}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                placeholder="Select availability"
              />
            ) : (
              <p>
                {
                  availabilities.find(
                    (avail) =>
                      avail._id === eventType.calendarXUserAvailabilityId,
                  )?.title
                }
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
