import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { Toaster } from "react-hot-toast";
import LoginPage from "./pages/LoginPage";
import PageNotFound from "./pages/PageNotFound";
import PrivateRoute from "./components/PrivateRoute";
import DashboardLayout from "./components/DashboardLayout";
import EmployeesUsers from "./components/EmployeeUsers";
import CalendarProfile from "./components/Calendar-X/CalendarProfile";
import PsychologistProfile from "./components/Calendar-X/PsychologistProfile";
import Psychologists from "./components/Pyschologists";
import PsychologistProfileRequest from "./components/PsychologistProfileRequest";
import Profile from "./components/Profile";
import Product from "./components/Product";
import Availability from "./components/Calendar-X/Availability";
import AvailabilityDetail from "./components/Calendar-X/AvailabilityDetail";
import EventTypes from "./components/Calendar-X/Events";
import EventDetails from "./components/Calendar-X/EventDetails";
import Bookings from "./components/Calendar-X/Bookings";
import GoogleCallback from "./components/GoogleCallback";
import ConnectGoogleAccount from "./components/ConnectGoogleAccount";

function App() {
  const { token } = useSelector((state: RootState) => state.adminAuth);
  return (
    <div className="font-wix">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route
            path="/login"
            element={token ? <Navigate to="/dashboard" /> : <LoginPage />}
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <DashboardLayout />
              </PrivateRoute>
            }
          >
            <Route path="profile" element={<Profile />} />
            <Route path="product" element={<Product />} />
            <Route path="google-oauth" element={<ConnectGoogleAccount />} />
            <Route path="redirect" element={<GoogleCallback />} />
            <Route path="employees/users" element={<EmployeesUsers />} />
            <Route path="psychologists/users" element={<Psychologists />} />
            <Route
              path="psychologists/requests"
              element={<PsychologistProfileRequest />}
            />
            <Route path="calendarX/profile" element={<CalendarProfile />} />
            <Route
              path="calendarX/psychologist-profile"
              element={<PsychologistProfile />}
            />
            <Route path="calendarX/availability" element={<Availability />} />
            <Route
              path="calendarX/availability/:availabilityScheduleId"
              element={<AvailabilityDetail />}
            />
            <Route path="calendarX/events" element={<EventTypes />} />
            <Route
              path="calendarX/events/:eventId"
              element={<EventDetails />}
            />
            <Route path="calendarX/bookings" element={<Bookings />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <Toaster
        toastOptions={{
          duration: 1000,
          style: {
            border: "1px solid #404040c2",
            color: "#1a1a1a",
            backgroundColor: "#d6d6d6",
            animation: "forwards",
            fontSize: "14px",
          },
          success: {
            iconTheme: {
              primary: "#404040",
              secondary: "#d6d6d6",
            },
          },
          error: {
            iconTheme: {
              primary: "#404040",
              secondary: "#d6d6d6",
            },
          },
        }}
      />
    </div>
  );
}

export default App;
