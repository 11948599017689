import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const fetchProfileChangeRequests = async (token: string) => {
  const response = await axios.get(
    `${REACT_APP_API_URL}/v1/admin/psychologist/profile-change-requests`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data.data;
};

export const approveProfileChange = async (
  calendarXUserId: string,
  token: string,
) => {
  const response = await axios.post(
    `${REACT_APP_API_URL}/v1/admin/psychologist/approve-profile-changes/${calendarXUserId}`,
    { action: true },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};
