import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchPsychologistsApi = async (token: string) => {
  const response = await axios.get(`${API_URL}/v1/admin/psychologist/all`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

export const createProductApi = async (product: any, token: string) => {
  const response = await axios.post(`${API_URL}/v1/admin/product`, product, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const fetchProductsByPsychologistIdApi = async (
  psychologistId: string,
  token: string,
) => {
  const response = await axios.get(
    `${API_URL}/v1/admin/product/all/${psychologistId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data.data;
};

export const updateProductApi = async (product: any, token: string) => {
  const { _id, ...productData } = product; // Extracting the product ID and the rest of the product data
  const response = await axios.patch(
    `${API_URL}/v1/admin/product/${_id}`,
    productData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export const deleteProductApi = async (productId: string, token: string) => {
  const response = await axios.delete(
    `${API_URL}/v1/admin/product/${productId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export const activateProductApi = async (productId: string, token: string) => {
  const response = await axios.post(
    `${API_URL}/v1/admin/product/active/${productId}`,
    { active: true },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};
