import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { loginApi, fetchProfileApi, resetPasswordApi } from "../../api/authApi";
import { RootState } from "../store";

interface User {
  _id: string;
  email: string;
  role: string;
  access: boolean;
  fullName: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface AuthState {
  token: string | null;
  loading: boolean;
  error: string | null;
  user: User | null;
}

const initialState: AuthState = {
  token: null,
  loading: false,
  error: null,
  user: null,
};

export const login = createAsyncThunk(
  "adminAuth/login",
  async (
    credentials: { email: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      return await loginApi(credentials);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchProfile = createAsyncThunk(
  "adminAuth/fetchProfile",
  async (token: string, { rejectWithValue }) => {
    try {
      return await fetchProfileApi(token);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resetPassword = createAsyncThunk(
  "adminAuth/resetPassword",
  async (newPassword: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    try {
      return await resetPasswordApi(newPassword, token);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const adminAuthSlice = createSlice({
  name: "adminAuth",
  initialState,
  reducers: {
    clearToken: (state) => {
      state.token = null;
      state.user = null;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.user = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearToken, setUser } = adminAuthSlice.actions;

export default adminAuthSlice.reducer;
