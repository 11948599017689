import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";

interface CustomSelectProps {
  name: string;
  value: string;
  onChange: (value: string) => void;
  options: { _id: string; title: string }[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  placeholder?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  name,
  value,
  onChange,
  options,
  isOpen,
  setIsOpen,
  placeholder = "Select an option",
}) => {
  const [dropdownPosition, setDropdownPosition] = useState("bottom");
  const selectRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (optionId: string) => {
    onChange(optionId);
    setIsOpen(false);
  };

  const selectedOption = options.find((option) => option._id === value);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const availableSpaceBelow = window.innerHeight - rect.bottom;
      const availableSpaceAbove = rect.top;
      setDropdownPosition(
        availableSpaceBelow < 150 && availableSpaceAbove > availableSpaceBelow
          ? "top"
          : "bottom",
      );
    }
  }, [isOpen]);

  return (
    <div className="relative mt-1 w-full" ref={selectRef}>
      <div
        className="flex h-10 cursor-pointer items-center justify-between rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light"
        onClick={handleToggleDropdown}
      >
        <span>{selectedOption ? selectedOption.title : placeholder}</span>
        <FaChevronDown className="h-3 w-3" />
      </div>
      {isOpen && (
        <ul
          className={`absolute z-10 mt-1 w-full rounded-md border border-primary bg-[#1a1b1b] text-light ${
            dropdownPosition === "top" ? "bottom-full mb-1" : "top-full mt-1"
          }`}
        >
          {options.map((option) => (
            <li
              key={option._id}
              onClick={() => handleOptionClick(option._id)}
              className="cursor-pointer px-3 py-2 hover:bg-[#333333]"
            >
              {option.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
