import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchPsychologistsApi,
  createProductApi,
  fetchProductsByPsychologistIdApi,
  activateProductApi,
  updateProductApi,
  deleteProductApi,
} from "../../api/productApi";
import { RootState } from "../store";

export interface Product {
  title: string;
  pricing: {
    IN: number;
    US: number;
    isFree: boolean;
    discount: number;
  };
  description: string;
  psychologistId: string;
  noOfSessions: number;
  type: string;
  durationInMinutes: number;
  active?: boolean;
  _id?: string;
}

interface Psychologist {
  _id: string;
  name: string;
}

interface ProductState {
  products: Product[];
  psychologists: Psychologist[];
  loading: boolean;
  error: string | null;
}

const initialState: ProductState = {
  products: [],
  psychologists: [],
  loading: false,
  error: null,
};

export const fetchPsychologists = createAsyncThunk(
  "product/fetchPsychologists",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const token = state.adminAuth.token;
      if (!token) {
        return rejectWithValue("Token is missing");
      }
      return await fetchPsychologistsApi(token);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createProduct = createAsyncThunk(
  "product/createProduct",
  async (product: Product, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const token = state.adminAuth.token;
      if (!token) {
        return rejectWithValue("Token is missing");
      }
      return await createProductApi(product, token);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchProductsByPsychologistId = createAsyncThunk(
  "product/fetchProductsByPsychologistId",
  async (psychologistId: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const token = state.adminAuth.token;
      if (!token) {
        return rejectWithValue("Token is missing");
      }
      return await fetchProductsByPsychologistIdApi(psychologistId, token);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const activateProduct = createAsyncThunk(
  "product/activateProduct",
  async (productId: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const token = state.adminAuth.token;
      if (!token) {
        return rejectWithValue("Token is missing");
      }
      return await activateProductApi(productId, token);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (productId: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const token = state.adminAuth.token;
      if (!token) {
        return rejectWithValue("Token is missing");
      }
      await deleteProductApi(productId, token);
      return productId;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async (product: Product, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const token = state.adminAuth.token;
      if (!token) {
        return rejectWithValue("Token is missing");
      }
      return await updateProductApi(product, token);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clearProducts(state) {
      state.products = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPsychologists.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPsychologists.fulfilled, (state, action) => {
        state.loading = false;
        state.psychologists = action.payload;
      })
      .addCase(fetchPsychologists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.products.push(action.payload);
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchProductsByPsychologistId.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductsByPsychologistId.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(fetchProductsByPsychologistId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(activateProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(activateProduct.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.products.findIndex(
          (product) => product._id === action.meta.arg,
        );
        if (index !== -1) {
          state.products[index].active = true;
        }
      })
      .addCase(activateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.products.findIndex(
          (product) => product._id === action.payload._id,
        );
        if (index !== -1) {
          state.products[index] = action.payload;
        }
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.products = state.products.filter(
          (product) => product._id !== action.payload,
        );
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearProducts } = productSlice.actions;

export default productSlice.reducer;
