import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaDotCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import { RootState, AppDispatch } from "../redux/store";
import { checkTokenValidity } from "../api/api";
import { clearToken, resetPassword } from "../redux/slices/adminAuthSlice";
import { resetProfile } from "../redux/slices/PsychologistProfileSlice";
import LoadingScreen from "./LoadingScreen";

const Profile = () => {
  const [profile, setProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const token = useSelector((state: RootState) => state.adminAuth.token);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      if (token) {
        try {
          const response = await checkTokenValidity(token);
          setProfile(response.data);
        } catch (error) {
          setError("Failed to fetch profile details");
        } finally {
          setLoading(false);
        }
      } else {
        setError("Token is missing");
        setLoading(false);
      }
    };

    fetchProfile();
  }, [token]);

  const handleResetPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      await dispatch(resetPassword(newPassword)).unwrap();
      dispatch(clearToken());
      dispatch(resetProfile());
      navigate("/login");
    } catch (error) {
      setError("Failed to reset password");
    }
  };

  if (loading)
    return (
      <p className="mt-32">
        <LoadingScreen />
      </p>
    );
  if (error) return <p>{error}</p>;

  return (
    <div>
      <div className="py-10">
        <div className="container m-auto w-[50%] rounded-xl border border-primary font-wix text-light">
          <div>
            <div className="flex items-center gap-x-3 px-6 pt-5">
              <button onClick={() => navigate(-1)} className="text-lg">
                <FaArrowLeft />
              </button>
              <p className="text-2xl font-semibold">Profile</p>
            </div>
            <p className="mt-2 border-b border-primary px-6 pb-5 font-medium text-light text-opacity-45">
              Manage your One Life Healthcare Profile
            </p>
          </div>
          {profile && (
            <div className="mb-4 px-6 py-8 font-semibold">
              <p>Full Name</p>
              <input
                type="text"
                value={profile.fullName}
                className="my-2 h-10 w-full rounded-md border border-primary bg-primary px-3 text-light text-opacity-50 outline-none"
              />
              <p className="pt-5">Email</p>
              <input
                type="mail"
                value={profile.email}
                className="my-2 h-10 w-full rounded-md border border-primary bg-primary px-3 text-light text-opacity-50 outline-none"
              />
              <p className="pt-5">Role</p>
              <input
                type="text"
                value={profile.role}
                className="my-2 h-10 w-full rounded-md border border-primary bg-primary px-3 text-light text-opacity-50 outline-none"
              />
              <p className="pt-5">Account Status</p>
              <div className="mt-2 flex items-center gap-x-2 px-3">
                <FaDotCircle
                  size={10}
                  className={profile.access ? "text-green-600" : "text-red-600"}
                />
                <p>{profile.access ? "Active" : "Inactive"}</p>
              </div>
            </div>
          )}
          <div className="border-t border-primary px-6">
            <h2 className="mt-5 text-xl font-bold">Reset Password</h2>
            <p className="mb-4 mt-2 text-[15px] text-light text-opacity-45">
              Please enter your new password and confirm it to reset your
              password
            </p>
            <form onSubmit={handleResetPassword}>
              <div className="relative mb-4">
                <label className="mb-2 block text-light">New Password</label>
                <input
                  type={newPasswordVisible ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full rounded-md border-2 border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-sm placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                  required
                />
                <button
                  type="button"
                  onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                  className="absolute inset-y-0 right-0 top-8 px-3 py-2 focus:outline-none"
                >
                  {newPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              <div className="relative mb-4">
                <label className="mb-2 block text-light">
                  Confirm Password
                </label>
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full rounded-md border-2 border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-sm placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                  required
                />
                <button
                  type="button"
                  onClick={() =>
                    setConfirmPasswordVisible(!confirmPasswordVisible)
                  }
                  className="absolute inset-y-0 right-0 top-8 px-3 py-2 focus:outline-none"
                >
                  {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              <div className="mb-8 mt-5 flex w-full justify-end">
                <button
                  type="submit"
                  className="flex w-40 rounded bg-[#fbfafb] px-4 py-2 text-right text-primary hover:opacity-80"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
