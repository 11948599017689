import React, { useState, useEffect, useRef } from "react";
import { FaChevronRight, FaSignOutAlt, FaUserAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearToken } from "../redux/slices/adminAuthSlice";
import { resetProfile } from "../redux/slices/PsychologistProfileSlice";

const ProfileDropdown = () => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(clearToken());
    dispatch(resetProfile());
    navigate("/login");
  };

  const handleProfile = () => {
    navigate("/dashboard/profile");
    setOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setOpen(!open)}
        className="flex items-center space-x-2 py-3 text-[17px]"
      >
        <FaChevronRight size={16} />
      </button>
      {open && (
        <div className="absolute -top-10 left-10 right-0  z-10 mt-2 w-48 rounded-md bg-secondary  shadow-lg">
          <button
            onClick={handleProfile}
            className="flex w-full items-center gap-x-3 px-4 py-2 text-left text-light hover:text-opacity-50"
          >
            <FaUserAlt />
            Profile
          </button>
          <div className="border-b border-secondary border-opacity-25"></div>
          <button
            onClick={handleLogout}
            className="flex w-full items-center gap-x-3 px-4 py-2 text-left text-light hover:text-opacity-50"
          >
            <FaSignOutAlt />
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
