import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/slices/adminAuthSlice";
import { RootState, AppDispatch } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { token, loading, error } = useSelector(
    (state: RootState) => state.adminAuth,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      toast.success("Login successful!");
      navigate("/dashboard");
    }
    if (error) {
      console.log(`Login failed: ${error}`);
      toast.error("Invalid email or password");
    }
  }, [token, error, navigate]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  return (
    <section className="flex h-auto min-h-screen flex-col items-center justify-center bg-[#161616] font-wix">
      <div className="-mt-20 w-[60%]">
        <div className="flex justify-center pb-4 text-center">
          <img
            src="../assets/one-life-logo-white.png"
            alt="one-life-logo"
            className="h-12 opacity-90"
          />
        </div>
        <p className="mb-10 text-center text-3xl font-bold text-[#ffffffdc]">
          Welcome back
        </p>
        <div className="m-auto flex h-auto w-full max-w-md items-center justify-center rounded-lg border-2 border-primary bg-[#1e1e1e] p-8 shadow-lg">
          <form onSubmit={handleSubmit} className="w-full">
            <div className="mb-4">
              <label className="mb-2 block text-base font-medium text-light">
                Email address
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                placeholder="johndoe@example.com"
                className="w-full rounded-md border-2 border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-[15px] placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
              />
            </div>
            <div className="relative mb-4">
              <div className="mb-2 flex items-center justify-between text-light">
                <label className="block text-base font-medium">Password</label>
              </div>
              <input
                type={passwordVisible ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                onFocus={() => setIsPasswordFocused(true)}
                onBlur={() => setIsPasswordFocused(false)}
                placeholder="•••••••••••••••••••"
                className="w-full rounded-md border-2 border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-[15px] placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className={`absolute inset-y-0 right-0 top-8 flex items-center rounded-r-md border-2 bg-[#1a1b1b] pl-3 pr-3 text-light ${
                  isPasswordFocused ? "border-[#535353]" : "border-primary"
                }`}
              >
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            <button
              type="submit"
              className="mt-3 w-full rounded-md bg-white px-4 py-2 text-sm font-semibold text-primary transition hover:bg-opacity-80"
              disabled={loading}
            >
              {loading ? "Signing In..." : "Sign In"}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
