import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../redux/store";
import { checkTokenValidity } from "../api/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { clearToken } from "../redux/slices/adminAuthSlice";
import { resetProfile } from "../redux/slices/PsychologistProfileSlice";

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { token } = useSelector((state: RootState) => state.adminAuth);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [toastShown, setToastShown] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      try {
        if (token) {
          await checkTokenValidity(token);
        } else {
          throw new Error("No token found");
        }
      } catch (error) {
        dispatch(clearToken());
        dispatch(resetProfile());
        if (!toastShown) {
          toast.error("Session Expired. Please log in again.");
          setToastShown(true);
        }
        navigate("/login");
      }
    };

    validateToken();
  }, [token, navigate, dispatch, toastShown]);

  return <>{children}</>;
};

export default PrivateRoute;
