import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  fetchCalendarProfileAPI,
  saveCalendarProfileAPI,
  updateCalendarProfileAPI,
  uploadProfileImageAPI,
} from "../../api/calendarProfileApi";

interface CalendarProfileState {
  fullName: string;
  email: string;
  about: string;
  location: string;
  image: string;
  imageFileName: string;
  loading: boolean;
  error: string | null;
}

const initialState: CalendarProfileState = {
  fullName: "",
  email: "",
  about: "",
  location: "",
  image: "",
  imageFileName: "",
  loading: false,
  error: null,
};

export const fetchCalendarProfile = createAsyncThunk(
  "calendarProfile/fetchProfile",
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    try {
      const data = await fetchCalendarProfileAPI(token);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

export const saveCalendarProfile = createAsyncThunk(
  "calendarProfile/saveProfile",
  async (
    profileData: {
      fullName: string;
      email: string;
      about: string;
      location: string;
      image: string;
    },
    { rejectWithValue, getState },
  ) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    try {
      const data = await saveCalendarProfileAPI(profileData, token);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

export const updateCalendarProfile = createAsyncThunk(
  "calendarProfile/updateProfile",
  async (
    profileData: {
      fullName: string;
      email: string;
      about: string;
      location: string;
      image: string;
    },
    { rejectWithValue, getState },
  ) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    try {
      const data = await updateCalendarProfileAPI(profileData, token);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

export const uploadProfileImage = createAsyncThunk(
  "calendarProfile/uploadProfileImage",
  async (file: File, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    try {
      const fileName = await uploadProfileImageAPI(file, token);
      return fileName;
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

const calendarProfileSlice = createSlice({
  name: "calendarProfile",
  initialState,
  reducers: {
    clearProfileState(state) {
      state.fullName = "";
      state.email = "";
      state.about = "";
      state.location = "";
      state.image = "";
      state.imageFileName = "";
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalendarProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCalendarProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.fullName = action.payload.fullName;
        state.email = action.payload.email;
        state.about = action.payload.about;
        state.location = action.payload.location;
        state.image = action.payload.image;
        state.imageFileName = action.payload.image;
      })
      .addCase(fetchCalendarProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(saveCalendarProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveCalendarProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.fullName = action.payload.fullName;
        state.email = action.payload.email;
        state.about = action.payload.about;
        state.location = action.payload.location;
        state.image = action.payload.image;
        state.imageFileName = action.payload.image;
      })
      .addCase(saveCalendarProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateCalendarProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCalendarProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.fullName = action.payload.fullName;
        state.email = action.payload.email;
        state.about = action.payload.about;
        state.location = action.payload.location;
        state.image = action.payload.image;
        state.imageFileName = action.payload.image;
      })
      .addCase(updateCalendarProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(uploadProfileImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadProfileImage.fulfilled, (state, action) => {
        state.loading = false;
        state.imageFileName = action.payload;
        sessionStorage.setItem("profileImageFileName", action.payload);
      })
      .addCase(uploadProfileImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearProfileState } = calendarProfileSlice.actions;
export default calendarProfileSlice.reducer;
