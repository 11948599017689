import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  uploadPsychologistProfileImageAPI,
  uploadPsychologistCertificateAPI,
  fetchPsychologistProfileAPI,
  savePsychologistProfileAPI,
  updatePsychologistProfileAPI,
} from "../../api/psychologistProfileApi";

interface PsychologistProfileState {
  name: string;
  title: string;
  experience: number;
  languages: string[];
  bio: string;
  expertise: string[];
  certificates: { name: string; url: string }[];
  profileImageFileName: string;
  loading: boolean;
  error: string | null;
}

const initialState: PsychologistProfileState = {
  name: "",
  title: "",
  experience: 0,
  languages: [],
  bio: "",
  expertise: [],
  certificates: [],
  profileImageFileName: "",
  loading: false,
  error: null,
};

export const fetchPsychologistProfile = createAsyncThunk(
  "psychologistProfile/fetchProfile",
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    try {
      const data = await fetchPsychologistProfileAPI(token);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

export const savePsychologistProfile = createAsyncThunk(
  "psychologistProfile/saveProfile",
  async (profileData: any, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    try {
      const data = await savePsychologistProfileAPI(profileData, token);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

export const uploadPsychologistProfileImage = createAsyncThunk(
  "psychologistProfile/uploadProfileImage",
  async (file: File, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    try {
      const fileName = await uploadPsychologistProfileImageAPI(file, token);
      return fileName;
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

export const uploadPsychologistCertificate = createAsyncThunk(
  "psychologistProfile/uploadCertificate",
  async (file: File, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    try {
      const fileName = await uploadPsychologistCertificateAPI(file, token);
      return fileName;
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

export const updatePsychologistProfile = createAsyncThunk(
  "psychologistProfile/updateProfile",
  async (profileData: any, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    try {
      const data = await updatePsychologistProfileAPI(profileData, token);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

const psychologistProfileSlice = createSlice({
  name: "psychologistProfile",
  initialState,
  reducers: { resetProfile: () => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(uploadPsychologistProfileImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadPsychologistProfileImage.fulfilled, (state, action) => {
        state.loading = false;
        state.profileImageFileName = action.payload;
        sessionStorage.setItem(
          "psychologistProfileImageFileName",
          action.payload,
        );
      })
      .addCase(uploadPsychologistProfileImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(uploadPsychologistCertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadPsychologistCertificate.fulfilled, (state, action) => {
        state.loading = false;
        sessionStorage.setItem(
          "psychologistCertificateFileName",
          action.payload,
        );
      })
      .addCase(uploadPsychologistCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchPsychologistProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPsychologistProfile.fulfilled, (state, action) => {
        state.loading = false;
        const data = action.payload;
        const profileData = data.newChanges ? data.newChanges : data;
        state.name = profileData.name;
        state.title = profileData.title;
        state.experience = profileData.experience;
        state.languages = profileData.languages;
        state.bio = profileData.bio;
        state.expertise = profileData.expertise;
        state.certificates = profileData.certificates.map(
          (cert: { name: string; url: string }) => ({
            ...cert,
            file: null,
          }),
        );
        state.profileImageFileName = profileData.image;
      })
      .addCase(fetchPsychologistProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(savePsychologistProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(savePsychologistProfile.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(savePsychologistProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updatePsychologistProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePsychologistProfile.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updatePsychologistProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { resetProfile } = psychologistProfileSlice.actions;

export default psychologistProfileSlice.reducer;
