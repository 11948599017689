import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../LoadingScreen";
import { FaArrowLeft } from "react-icons/fa";

const { REACT_APP_API_URL } = process.env;

interface EventType {
  _id: string;
  title: string;
  description: string;
  durationInMinutes: number;
}

const EventTypes: React.FC = () => {
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(true);
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const token = useSelector((state: RootState) => state.adminAuth.token);

  useEffect(() => {
    const fetchEventTypes = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_URL}/v1/admin/calendar-x/event-type/all`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setLoadingData(false);
        setEventTypes(response.data.data);
      } catch (error) {
        console.error("Error fetching event types:", error);
      }
    };

    fetchEventTypes();
  }, [token]);

  const handleClick = (id: string) => {
    navigate(`/dashboard/calendarX/events/${id}`);
  };

  if (loadingData) {
    return (
      <p className="mt-32">
        <LoadingScreen />
      </p>
    );
  }

  return (
    <div className="min-h-screen text-white">
      <div className="flex h-[63px] items-center gap-x-4 px-6 text-white text-opacity-90">
        <button onClick={() => navigate(-1)} className="text-lg">
          <FaArrowLeft />
        </button>
        <h1 className="text-2xl font-semibold">Event Types</h1>
      </div>{" "}
      <div className="border-b border-primary"></div>
      <div className="py-6 sm:px-2 lg:px-6">
        <p className="mb-4 text-light">
          Create events to share for people to book on your calendar.
        </p>
        <div className="rounded-lg p-1">
          {eventTypes.map((eventType) => (
            <div
              key={eventType._id}
              className="mb-4 flex cursor-pointer items-center justify-between rounded-lg bg-secondary p-6"
              onClick={(e) => {
                handleClick(eventType._id);
              }}
            >
              <div>
                <h2 className="text-xl font-semibold text-white">
                  {eventType.title}
                </h2>
                <p className="mt-1 text-light">{eventType.description}</p>
              </div>
              <div className="flex items-center text-sm">
                <span className="mr-4 rounded-full bg-primary px-3 py-1 text-light">
                  {eventType.durationInMinutes}m
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventTypes;
