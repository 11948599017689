import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  fetchUsersApi,
  addUserApi,
  updateUserRoleApi,
  deleteUserApi,
  activateUserApi,
  disableUserApi,
} from "../../api/userApi";

interface User {
  _id: string;
  fullName: string;
  email: string;
  role: string;
  access: boolean;
}

interface UsersState {
  users: User[];
  loading: boolean;
  error: string | null;
}

const initialState: UsersState = {
  users: [],
  loading: false,
  error: null,
};

export const fetchUsers = createAsyncThunk(
  "adminUsers/fetchUsers",
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("No token provided");
    }
    try {
      return await fetchUsersApi(token);
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

export const addUser = createAsyncThunk(
  "adminUsers/addUser",
  async (
    user: { fullName: string; email: string; password: string; role: string },
    { rejectWithValue, getState },
  ) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("No token provided");
    }
    try {
      return await addUserApi(user, token);
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

export const updateUserRole = createAsyncThunk(
  "adminUsers/updateUserRole",
  async (
    { userId, role }: { userId: string; role: string },
    { rejectWithValue, getState },
  ) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("No token provided");
    }
    try {
      return await updateUserRoleApi(userId, role, token);
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

export const deleteUser = createAsyncThunk(
  "adminUsers/deleteUser",
  async (userId: string, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("No token provided");
    }
    try {
      return await deleteUserApi(userId, token);
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

export const activateUser = createAsyncThunk(
  "adminUsers/activateUser",
  async (userId: string, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("No token provided");
    }
    try {
      return await activateUserApi(userId, token);
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

export const disableUser = createAsyncThunk(
  "adminUsers/disableUser",
  async (userId: string, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("No token provided");
    }
    try {
      return await disableUserApi(userId, token);
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || error.response.data,
      );
    }
  },
);

const adminUsersSlice = createSlice({
  name: "adminUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(addUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload);
      })
      .addCase(addUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateUserRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserRole.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.users.findIndex(
          (user) => user._id === action.payload._id,
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
      })
      .addCase(updateUserRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.filter(
          (user) => user._id !== action.payload._id,
        );
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(activateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(activateUser.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.users.findIndex(
          (user) => user._id === action.payload._id,
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
      })
      .addCase(activateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(disableUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(disableUser.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.users.findIndex(
          (user) => user._id === action.payload._id,
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
      })
      .addCase(disableUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default adminUsersSlice.reducer;
