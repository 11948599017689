import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaSearch,
  FaEdit,
  FaTrash,
  FaEye,
  FaEyeSlash,
  FaArrowLeft,
  FaCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import { RootState, AppDispatch } from "../redux/store";
import {
  fetchUsers,
  addUser,
  updateUserRole,
  deleteUser,
  activateUser,
  disableUser,
} from "../redux/slices/adminUsersSlice";
import LoadingScreen from "./LoadingScreen";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import "../styles/ToggleSwitch.css";

interface User {
  _id: string;
  fullName: string;
  email: string;
  role: string;
  access: boolean;
}

const EmployeesUsers = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("password");
  const [role, setRole] = useState("guest");
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingUserId, setEditingUserId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState("");
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [toggleUserId, setToggleUserId] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const { users, loading, error } = useSelector(
    (state: RootState) => state.adminUsers,
  );

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleCreateUser = async () => {
    await dispatch(addUser({ fullName, email, password, role }));
    setShowModal(false);
    setFullName("");
    setEmail("");
    setPassword("");
    setRole("guest");
    dispatch(fetchUsers());
    toast.success("User created successfully!");
  };

  const handleEditUser = async () => {
    await dispatch(updateUserRole({ userId: editingUserId, role }));
    setShowModal(false);
    setIsEditing(false);
    setFullName("");
    setEmail("");
    setPassword("");
    setRole("guest");
    setEditingUserId("");
    dispatch(fetchUsers());
    toast.success("User record updated successfully!");
  };

  const handleDeleteUser = async () => {
    await dispatch(deleteUser(deletingUserId));
    setShowDeleteModal(false);
    setDeletingUserId("");
    dispatch(fetchUsers());
    toast.success("User deleted successfully!");
  };

  const handleToggleUser = async () => {
    if (showActivateModal) {
      await dispatch(activateUser(toggleUserId));
      setShowActivateModal(false);
      toast.success("User activated!");
    } else {
      await dispatch(disableUser(toggleUserId));
      setShowDisableModal(false);
      toast.success("User deactivated!");
    }
    setToggleUserId("");
    dispatch(fetchUsers());
  };

  const openEditModal = (user: User) => {
    setFullName(user.fullName);
    setEmail(user.email);
    setRole(user.role);
    setEditingUserId(user._id);
    setIsEditing(true);
    setShowModal(true);
  };

  const openDeleteModal = (userId: string) => {
    setDeletingUserId(userId);
    setShowDeleteModal(true);
  };

  const openToggleModal = (userId: string, currentAccess: boolean) => {
    setToggleUserId(userId);
    if (currentAccess) {
      setShowDisableModal(true);
    } else {
      setShowActivateModal(true);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="font-wix">
      <div className="flex h-[63px] items-center gap-x-4 px-6 text-white text-opacity-90">
        <button onClick={() => navigate(-1)} className="text-lg">
          <FaArrowLeft />
        </button>
        <h1 className="text-2xl font-semibold">Employee Users</h1>
      </div>{" "}
      <div className="border-b border-primary"></div>
      <div className="p-8">
        <div className="mb-8 mt-2 flex justify-between">
          <div className="relative w-1/3">
            <input
              type="text"
              placeholder="Search users..."
              value={searchQuery}
              onChange={handleSearch}
              className="w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
            />
            <FaSearch className="absolute right-4 top-3 text-tertiary" />
          </div>
          <button
            className="flex items-center rounded bg-[#fbfafb] px-4 py-2 font-semibold text-primary hover:opacity-80"
            onClick={() => {
              setShowModal(true);
              setIsEditing(false);
            }}
          >
            <img
              src="../../assets/plus.png"
              alt="plus"
              className="mr-3 h-3 w-3"
            />
            Create User
          </button>
        </div>

        {showModal && (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="fixed inset-0 flex items-center justify-center">
              <div className="w-1/3 rounded bg-primary p-6 shadow-lg">
                <h2 className="mb-8 text-xl font-bold">
                  {isEditing ? "Edit User" : "Create User"}
                </h2>
                <div className="mb-4">
                  <label className="mb-1 block">Full Name</label>
                  <input
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    className="w-full rounded-md border border-primary bg-primary p-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                    disabled={isEditing}
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 block">Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full rounded-md border border-primary bg-primary p-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                    disabled={isEditing}
                  />
                </div>
                {!isEditing && (
                  <div className="relative mb-4">
                    <label className="mb-1 block">Password</label>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full rounded-md border border-primary bg-primary p-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute inset-y-0 right-0 top-7 flex items-center px-3"
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                )}
                <div className="mb-4">
                  <label className="mb-1 block">Role</label>
                  <select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    className="w-full rounded-md border border-primary bg-primary p-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                  >
                    <option value="guest">Guest</option>
                    <option value="superAdmin">Superadmin</option>
                    <option value="admin">Admin</option>
                    <option value="psychologist">Psychologist</option>
                    <option value="sales">Sales</option>
                  </select>
                </div>
                <div className="flex justify-end">
                  <button
                    className="mr-2 rounded px-4 py-2 hover:opacity-80"
                    onClick={() => {
                      setShowModal(false);
                      setIsEditing(false);
                      setFullName("");
                      setEmail("");
                      setPassword("");
                      setRole("guest");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="rounded bg-light px-4 py-2 text-primary hover:opacity-80"
                    onClick={isEditing ? handleEditUser : handleCreateUser}
                  >
                    {isEditing ? "Update" : "Create"}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {showDeleteModal && (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="fixed inset-0 flex items-center justify-center">
              <div className="w-1/3 rounded bg-primary p-6 shadow-lg">
                <div className="mb-2 flex items-center gap-x-2">
                  <FaExclamationCircle size={24} className="text-red-700" />
                  <h2 className="text-xl font-bold text-white">Delete</h2>
                </div>
                <p>Are you sure you want to delete this user?</p>
                <div className="mt-4 flex justify-end">
                  <button
                    className="mr-2 px-4 py-2 font-medium hover:opacity-80"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="hover:opacity rounded bg-[#fbfafb] px-4 py-2 font-medium text-primary"
                    onClick={handleDeleteUser}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {showActivateModal && (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="fixed inset-0 flex items-center justify-center">
              <div className="w-1/3 rounded-xl bg-primary p-6 shadow-lg">
                <div className="mb-2 flex items-center gap-x-2">
                  <FaCheckCircle size={24} />
                  <h2 className="text-xl font-bold text-white">
                    Activate User
                  </h2>
                </div>
                <p>Are you sure you want to activate this user?</p>
                <div className="mt-4 flex justify-end">
                  <button
                    className="mr-2 px-4 py-2 font-medium hover:opacity-80"
                    onClick={() => setShowActivateModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="rounded bg-[#fbfafb] px-4 py-2 font-medium text-primary hover:bg-opacity-80"
                    onClick={handleToggleUser}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {showDisableModal && (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="fixed inset-0 flex items-center justify-center">
              <div className="w-1/3 rounded-xl bg-primary p-6 shadow-lg">
                <div className="mb-2 flex items-center gap-x-2">
                  <FaExclamationCircle size={24} className="text-red-700" />
                  <h2 className="text-xl font-bold text-white">
                    Deactivate User
                  </h2>
                </div>
                <p>Are you sure you want to disable this user?</p>
                <div className="mt-4 flex justify-end">
                  <button
                    className="mr-2 px-4 py-2 font-medium hover:opacity-80"
                    onClick={() => setShowDisableModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="rounded bg-[#fbfafb] px-4 py-2 font-medium text-primary hover:bg-opacity-80"
                    onClick={handleToggleUser}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {loading && <LoadingScreen />}
        {error && <p>Error: {error}</p>}
        {!loading && !error && (
          <>
            {users.filter(
              (user) =>
                user.fullName &&
                user.fullName.toLowerCase().includes(searchQuery.toLowerCase()),
            ).length === 0 ? (
              <p>No user found</p>
            ) : (
              <table className="min-w-full border border-primary bg-primary text-left">
                <thead className="bg-secondary">
                  <tr>
                    <th className="border-b border-primary px-4 py-2">
                      Full Name
                    </th>
                    <th className="border-b border-primary px-4 py-2">Email</th>
                    <th className="border-b border-primary px-4 py-2">Role</th>
                    <th className="border-b border-primary px-4 py-2">
                      Active
                    </th>
                    <th className="border-b border-primary px-4 py-2">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users
                    .filter(
                      (user) =>
                        user.fullName &&
                        user.fullName
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()),
                    )
                    .map((user: User) => (
                      <tr
                        key={user._id}
                        className="cursor-pointer hover:bg-[#1e1e1e38]"
                      >
                        <td className="border-b border-primary px-4 py-2">
                          {user.fullName}
                        </td>
                        <td className="border-b border-primary px-4 py-2">
                          {user.email}
                        </td>
                        <td className="border-b border-primary px-4 py-2">
                          {user.role}
                        </td>
                        <td className="border-b border-primary px-4 pb-2">
                          <label className="toggle-switch inline-flex cursor-pointer items-center">
                            <input
                              type="checkbox"
                              checked={user.access}
                              onChange={() =>
                                openToggleModal(user._id, user.access)
                              }
                            />
                            <span className="slider"></span>
                            <span className="ml-2"></span>
                          </label>
                        </td>

                        <td className="border-b border-primary px-4 py-2">
                          <button
                            className="mr-6 text-light hover:opacity-70"
                            onClick={() => openEditModal(user)}
                          >
                            <FaEdit />
                          </button>
                          <button
                            className="text-light hover:opacity-70"
                            onClick={() => openDeleteModal(user._id)}
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeesUsers;
