import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchPsychologistProfileAPI = async (token: string) => {
  const response = await axios.get(
    `${API_URL}/v1/admin/calendar-x/psychologist-profile`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data.data;
};

export const savePsychologistProfileAPI = async (
  profileData: any,
  token: string,
) => {
  const response = await axios.post(
    `${API_URL}/v1/admin/calendar-x/psychologist-profile`,
    profileData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data.data;
};

export const uploadPsychologistProfileImageAPI = async (
  file: File,
  token: string,
) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await axios.put(
    `${API_URL}/v1/admin/calendar-x/psychologist-profile-image`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response.data.data.fileName;
};

export const uploadPsychologistCertificateAPI = async (
  file: File,
  token: string,
) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await axios.put(
    `${API_URL}/v1/admin/calendar-x/psychologist-profile-certificate`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response.data.data.fileName;
};

export const updatePsychologistProfileAPI = async (
  profileData: any,
  token: string,
) => {
  const response = await axios.patch(
    `${API_URL}/v1/admin/calendar-x/psychologist-profile`,
    profileData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data.data;
};
