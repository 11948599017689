import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../redux/store";
import {
  getProfileChangeRequests,
  approveProfileChangeRequest,
} from "../redux/slices/PsychologistProfileRequestSlice";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";

const { REACT_APP_S3_URL } = process.env;

const PsychologistProfileRequest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { requests, loading, error, approving } = useSelector(
    (state: RootState) => state.psychologistProfileRequest,
  );
  const [selectedRequest, setSelectedRequest] = useState<any | null>(null);
  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [selectedRequest]);

  useEffect(() => {
    dispatch(getProfileChangeRequests());
  }, [dispatch]);

  const handleViewClick = (request: any) => {
    setSelectedRequest(request);
  };

  const handleApproveClick = () => {
    setShowApproveConfirm(true);
  };

  const handleConfirmApprove = async () => {
    if (selectedRequest) {
      dispatch(approveProfileChangeRequest(selectedRequest.calendarXUserId));
      setShowApproveConfirm(false);
      setSelectedRequest(null);
    }
  };

  const handleCloseModal = () => {
    setSelectedRequest(null);
  };

  const handleCancelApprove = () => {
    setShowApproveConfirm(false);
  };

  return (
    <>
      <div className="flex h-[63px] items-center gap-x-4 px-6 text-white text-opacity-90">
        <button onClick={() => navigate(-1)} className="text-lg">
          <FaArrowLeft />
        </button>
        <h1 className="text-2xl font-semibold">Requests</h1>
      </div>
      <div className="border-b border-primary"></div>
      <div className="container mx-auto p-8">
        {loading ? (
          <p className="mt-16">
            <LoadingScreen />
          </p>
        ) : (
          <>
            {error && <p className="text-red-500">{error}</p>}
            {requests.length === 0 ? (
              <p className="mt-5 text-center">No requests found.</p>
            ) : (
              <table className="min-w-full border border-primary bg-primary text-left">
                <thead className="bg-secondary">
                  <tr>
                    <th className="border-b border-primary px-4 py-2">Image</th>
                    <th className="border-b border-primary px-4 py-2">Name</th>
                    <th className="border-b border-primary px-4 py-2">Title</th>
                    <th className="border-b border-primary px-4 py-2">
                      Status
                    </th>
                    <th className="border-b border-primary px-4 py-2">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {requests.map((request: any) => (
                    <tr
                      key={request._id}
                      className="cursor-pointer hover:bg-[#1e1e1e38]"
                    >
                      <td className="border-b border-primary px-4 py-2">
                        <img
                          src={`${REACT_APP_S3_URL}/uploads/psychologists/profile/${request.newChanges.image}`}
                          alt={request.newChanges.name}
                          className="h-10 w-10 rounded-full object-cover"
                        />
                      </td>
                      <td className="border-b border-primary px-4 py-2">
                        {request.newChanges.name}
                      </td>
                      <td className="border-b border-primary px-4 py-2">
                        {request.newChanges.title}
                      </td>
                      <td className="border-b border-primary px-4 py-2">
                        {request.synced ? "Approved" : "Waiting"}
                      </td>
                      <td className="border-b border-primary px-4 py-2">
                        <button
                          onClick={() => handleViewClick(request)}
                          className="text-blue-500 hover:underline"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {selectedRequest && (
              <div>
                <div className="fixed inset-0 bg-black bg-opacity-50"></div>
                <div className="fixed inset-0 flex items-center justify-center ">
                  <div className="scrollbar max-h-[80%] w-1/3 overflow-y-auto rounded bg-primary p-6 shadow-lg">
                    <h2 className="mb-4 text-xl font-bold">View Changes</h2>
                    <form className="">
                      <div className="mb-4">
                        <label className="mb-1 block">Name</label>
                        <input
                          type="text"
                          value={selectedRequest.newChanges.name}
                          readOnly
                          className="w-full rounded-md border border-primary bg-primary p-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="mb-1 block">Title</label>
                        <input
                          type="text"
                          value={selectedRequest.newChanges.title}
                          readOnly
                          className="w-full rounded-md border border-primary bg-primary p-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="mb-1 block">Bio</label>
                        <textarea
                          ref={textareaRef}
                          value={selectedRequest.newChanges.bio}
                          readOnly
                          className="scrollbar w-full rounded-md border border-primary bg-primary p-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="mb-1 block">Experience</label>
                        <input
                          type="text"
                          value={selectedRequest.newChanges.experience}
                          readOnly
                          className="w-full rounded-md border border-primary bg-primary p-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="mb-1 block">Certificates</label>
                        <ul>
                          {selectedRequest.newChanges.certificates.map(
                            (cert: any) => (
                              <li key={cert.url}>
                                <a
                                  href={`${REACT_APP_S3_URL}/uploads/psychologists/certificates/${cert.url}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-500 hover:underline"
                                >
                                  {cert.name}
                                </a>
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                      <div className="flex justify-end space-x-4">
                        <button
                          type="button"
                          onClick={handleCloseModal}
                          className="mr-2 rounded px-4 py-2 hover:opacity-80"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          onClick={handleApproveClick}
                          className="rounded bg-light px-4 py-2 text-primary hover:opacity-80"
                          disabled={approving}
                        >
                          {approving ? "Approving..." : "Approve"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}

            {showApproveConfirm && (
              <>
                <div className="fixed inset-0 bg-black bg-opacity-50"></div>
                <div className="fixed inset-0 flex items-center justify-center">
                  <div className="w-1/3 rounded-xl bg-primary p-6 shadow-lg">
                    <div className="mb-2 flex items-center gap-x-2">
                      <FaCheckCircle size={24} />
                      <h2 className="text-xl font-bold text-white">
                        Approve Request
                      </h2>
                    </div>
                    <p>Are you sure you want to approve this profile change?</p>
                    <div className="mt-4 flex justify-end space-x-4">
                      <button
                        onClick={handleCancelApprove}
                        className="mr-2 px-4 py-2 font-medium hover:opacity-80"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleConfirmApprove}
                        className="rounded bg-[#fbfafb] px-4 py-2 font-medium text-primary hover:bg-opacity-80"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PsychologistProfileRequest;
