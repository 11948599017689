import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import adminAuthReducer from "./slices/adminAuthSlice";
import adminUsersSlice from "./slices/adminUsersSlice";
import CalendarProfileSlice from "./slices/CalendarProfileSlice";
import PsychologistProfileSlice from "./slices/PsychologistProfileSlice";
import pyschologistSlice from "./slices/pyschologistSlice";
import PsychologistProfileRequestSlice from "./slices/PsychologistProfileRequestSlice";
import productReducer from "./slices/productSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["adminAuth"],
};

const rootReducer = combineReducers({
  adminAuth: adminAuthReducer,
  adminUsers: adminUsersSlice,
  calendarProfile: CalendarProfileSlice,
  psychologistProfile: PsychologistProfileSlice,
  psychologists: pyschologistSlice,
  psychologistProfileRequest: PsychologistProfileRequestSlice,
  product: productReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
