import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const { REACT_APP_API_URL } = process.env;

const GoogleCallback: React.FC = () => {
  const token = useSelector((state: RootState) => state.adminAuth.token);
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log(code);

    if (code) {
      // Send the code to your backend server
      axios.post(`${REACT_APP_API_URL}/v1/admin/google-oauth-integration/verify-oauth-code`, { code }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
          .then(response => {
            console.log('Success:', response.data);
            setStatus(true)
          })
          .catch(error => {
            // Handle error
            console.error('Error:', error);
            setError(true)
          });
    }
  }, [navigate, token]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      {!status && !error && 
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">Processing...</h2>
          <p>Please wait while we complete the authentication process.</p>
        </div>
      }

      {status && !error && 
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">Success</h2>
          <p>Close window!</p>
        </div>
      }

      {error && 
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">Error</h2>
          <p>Something went wrong :(</p>
        </div>
      }
    </div>
  );
};

export default GoogleCallback;
