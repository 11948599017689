import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../redux/store";
import {
  getPsychologists,
  removePsychologist,
} from "../redux/slices/pyschologistSlice";
import { FaArrowLeft, FaExclamationCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";

const { REACT_APP_S3_URL } = process.env;

const Psychologists = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { psychologists, loading, error } = useSelector(
    (state: RootState) => state.psychologists,
  );

  const [selectedPsychologistId, setSelectedPsychologistId] = useState<
    string | null
  >(null);

  useEffect(() => {
    dispatch(getPsychologists());
  }, [dispatch]);

  const handleDeleteClick = (id: string) => {
    setSelectedPsychologistId(id);
  };

  const handleConfirmDelete = () => {
    if (selectedPsychologistId) {
      dispatch(removePsychologist(selectedPsychologistId));
      setSelectedPsychologistId(null);
    }
  };

  const handleCancelDelete = () => {
    setSelectedPsychologistId(null);
  };

  return (
    <>
      <div className="flex h-[63px] items-center gap-x-4 px-6 text-white text-opacity-90">
        <button onClick={() => navigate(-1)} className="text-lg">
          <FaArrowLeft />
        </button>
        <h1 className="text-2xl font-semibold">Psychologists</h1>
      </div>
      <div className="border-b border-primary"></div>
      {loading ? (
        <p className="mt-20">
          <LoadingScreen />
        </p>
      ) : (
        <div className="container mx-auto p-8">
          {error && <p className="text-red-500">{error}</p>}
          <table className="min-w-full border border-primary bg-primary text-left">
            <thead className="bg-secondary">
              <tr>
                <th className="border-b border-primary px-4 py-2">Image</th>
                <th className="border-b border-primary px-4 py-2">Name</th>
                <th className="border-b border-primary px-4 py-2">Title</th>
                <th className="border-b border-primary px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {psychologists.map((psychologist) => (
                <tr
                  key={psychologist._id}
                  className="cursor-pointer hover:bg-[#1e1e1e38]"
                >
                  <td className="border-b border-primary px-4 py-3">
                    <img
                      src={`${REACT_APP_S3_URL}/uploads/psychologists/profile/${psychologist.image}`}
                      alt={psychologist.name}
                      className="h-10 w-10 rounded-full object-cover"
                    />
                  </td>
                  <td className="border-b border-primary px-4 py-3">
                    {psychologist.name}
                  </td>
                  <td className="border-b border-primary px-4 py-3">
                    {psychologist.title}
                  </td>
                  <td className="border-b border-primary px-4 py-3">
                    <button
                      onClick={() => handleDeleteClick(psychologist._id)}
                      className="text-red-500 hover:underline"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {selectedPsychologistId && (
            <div>
              <div className="fixed inset-0 bg-black bg-opacity-50"></div>
              <div className="fixed inset-0 flex items-center justify-center">
                <div className="w-1/3 rounded-xl bg-primary p-6 shadow-lg">
                  <div className="mb-2 flex items-center gap-x-2">
                    <FaExclamationCircle size={24} className="text-red-700" />
                    <h2 className="text-xl font-bold text-white">
                      Delete Psychologist
                    </h2>
                  </div>
                  <p>Are you sure you want to delete this psychologist?</p>
                  <div className="mt-4 flex justify-end space-x-4">
                    <button
                      onClick={handleCancelDelete}
                      className="mr-2 px-4 py-2 font-medium hover:opacity-80"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleConfirmDelete}
                      className="rounded bg-[#fbfafb] px-4 py-2 font-medium text-primary hover:bg-opacity-80"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Psychologists;
