import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FaArrowLeft, FaExclamationCircle, FaGlobe } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Plus } from "react-feather";
import LoadingScreen from "../LoadingScreen";

interface ITimeslot {
  startTime: number;
  endTime: number;
}

interface IDay {
  day: number;
  timeslots: ITimeslot[];
}

interface IAvailability {
  _id: string;
  title: string;
  timezone: string;
  days: IDay[];
  isDefault?: boolean;
}

const { REACT_APP_API_URL } = process.env;

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const defaultAvailability: IAvailability = {
  _id: "",
  title: "Default Availability 1",
  timezone: "Asia/Kolkata",
  days: [
    { day: 0, timeslots: [] },
    { day: 1, timeslots: [{ startTime: 540, endTime: 1080 }] },
    { day: 2, timeslots: [{ startTime: 540, endTime: 1080 }] },
    { day: 3, timeslots: [{ startTime: 540, endTime: 1080 }] },
    { day: 4, timeslots: [{ startTime: 540, endTime: 1080 }] },
    { day: 5, timeslots: [{ startTime: 540, endTime: 1080 }] },
    { day: 6, timeslots: [] },
  ],
};

const timezones = [
  "Asia/Kolkata",
  "America/New_York",
  "Europe/London",
  "Australia/Sydney",
  "Asia/Tokyo",
  "America/Los_Angeles",
  "Europe/Berlin",
];

const Availability: React.FC = () => {
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(true);
  const token = useSelector((state: RootState) => state.adminAuth.token);
  const [availabilities, setAvailabilities] = useState<IAvailability[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [newAvailability, setNewAvailability] =
    useState<IAvailability>(defaultAvailability);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [availabilityToDelete, setAvailabilityToDelete] = useState<
    string | null
  >(null);
  const [showMenu, setShowMenu] = useState<string | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [showDefaultDeletePopup, setShowDefaultDeletePopup] = useState(false);

  useEffect(() => {
    const fetchAvailabilities = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_URL}/v1/admin/calendar-x/availability`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (response.data.success) {
          setAvailabilities(response.data.data);
        }
        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
        console.error("Error fetching availabilities:", error);
      }
    };

    fetchAvailabilities();
  }, [token]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleClick = (id: string) => {
    navigate(`/dashboard/calendarX/availability/${id}`);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setNewAvailability((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/v1/admin/calendar-x/availability`,
        newAvailability,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success) {
        setAvailabilities((prev) => [...prev, response.data.data]);
        setShowForm(false);
        setNewAvailability(defaultAvailability);
      }
    } catch (error) {
      console.error("Error creating availability:", error);
    }
  };

  const handleDelete = async () => {
    if (!availabilityToDelete) return;

    const availability = availabilities.find(
      (a) => a._id === availabilityToDelete,
    );
    if (availability?.isDefault) {
      setShowDefaultDeletePopup(true);
      setShowDeletePopup(false);
      return;
    }

    try {
      const response = await axios.delete(
        `${REACT_APP_API_URL}/v1/admin/calendar-x/availability/${availabilityToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success) {
        setAvailabilities((prev) =>
          prev.filter((a) => a._id !== availabilityToDelete),
        );
        setShowDeletePopup(false);
        setAvailabilityToDelete(null);
        setShowMenu(null);
      }
    } catch (error) {
      console.error("Error deleting availability:", error);
    }
  };

  const formatTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMins = mins.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMins} ${period}`;
  };

  const formatDayTimes = (days: IDay[]) => {
    const timeslotGroups: { [key: string]: string[] } = {};

    days.forEach((day) => {
      day.timeslots.forEach((slot) => {
        const timeRange = `${formatTime(slot.startTime)} - ${formatTime(slot.endTime)}`;
        if (timeslotGroups[timeRange]) {
          timeslotGroups[timeRange].push(dayNames[day.day]);
        } else {
          timeslotGroups[timeRange] = [dayNames[day.day]];
        }
      });
    });

    return Object.keys(timeslotGroups)
      .map((timeRange) => {
        const days = timeslotGroups[timeRange];
        const formattedDays =
          days.length > 1 ? `${days[0]} - ${days[days.length - 1]}` : days[0];
        return `${formattedDays}, ${timeRange}`;
      })
      .join(" | ");
  };

  if (loadingData) {
    return (
      <p className="mt-32">
        <LoadingScreen />
      </p>
    );
  }

  return (
    <div className="flex h-screen flex-col font-wix text-light">
      <div className="flex h-[63px] items-center justify-between px-6">
        <div className="flex h-[63px] items-center gap-x-4 text-white text-opacity-90">
          <button onClick={() => navigate(-1)} className="text-lg">
            <FaArrowLeft />
          </button>
          <h1 className="text-2xl font-semibold">Availability</h1>
        </div>{" "}
        <button
          className="flex items-center justify-center rounded bg-light py-1.5 pl-3 pr-4 text-[17px] font-semibold text-primary transition hover:bg-opacity-80"
          onClick={() => setShowForm(true)}
        >
          <Plus className="h-4" /> New
        </button>
      </div>
      <div className="border-b border-primary"></div>
      {showForm && (
        <div className="fixed inset-0 left-80 flex items-center justify-center bg-black bg-opacity-50 text-light">
          <div className="rounded bg-secondary p-6 shadow-lg">
            <h2 className="mb-4 text-lg font-semibold text-[#fffffff1]">
              Add a new schedule
            </h2>
            <form onSubmit={handleFormSubmit} className="w-80">
              <div className="mb-2">
                <label className="block text-sm font-medium">Title</label>
                <input
                  type="text"
                  name="title"
                  value={newAvailability.title}
                  onChange={handleInputChange}
                  className="my-2 w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-placeholder focus:border-[#535353] focus:outline-none"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium">Timezone</label>
                <select
                  name="timezone"
                  value={newAvailability.timezone}
                  onChange={handleInputChange}
                  className="my-2 w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-placeholder focus:border-[#535353] focus:outline-none"
                >
                  {timezones.map((tz) => (
                    <option key={tz} value={tz}>
                      {tz}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-3 flex items-center justify-end text-[15px]">
                <button
                  type="button"
                  className="rounded px-4 py-2 font-medium text-light transition hover:bg-opacity-80"
                  onClick={() => setShowForm(false)}
                >
                  Close
                </button>{" "}
                <button
                  type="submit"
                  className="ml-4 rounded bg-light px-4 py-2 font-medium text-primary transition hover:bg-opacity-80"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showDeletePopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-[420px] rounded bg-secondary p-6 shadow-lg">
            <h2 className="mb-4 flex items-center gap-x-2 text-xl font-semibold text-[#ffffffea]">
              <FaExclamationCircle className="text-red-600" />
              Delete Availability
            </h2>
            <p className="mb-6 text-light">
              Are you sure you want to delete this availability? This action
              cannot be undone.
            </p>
            <div className="flex justify-end">
              <button
                className="rounded px-4 py-2 font-medium text-light transition hover:opacity-80"
                onClick={() => setShowDeletePopup(false)}
              >
                Cancel
              </button>
              <button
                className="ml-2 rounded bg-light px-4 py-2 font-medium text-primary transition hover:opacity-80"
                onClick={handleDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showDefaultDeletePopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-[420px] rounded bg-secondary p-6 shadow-lg">
            <h2 className="mb-4 flex items-center gap-x-2 text-xl font-semibold text-[#ffffffea]">
              <FaExclamationCircle className="text-red-600" />
              Delete Availability
            </h2>
            <p className="mb-6 text-light">
              Default availability cannot be deleted.
            </p>
            <div className="flex justify-end">
              <button
                className="ml-2 rounded bg-light px-4 py-2 font-medium text-primary transition hover:opacity-80"
                onClick={() => setShowDefaultDeletePopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex-1 space-y-3 overflow-y-auto p-6">
        {availabilities.map((availability) => (
          <div
            key={availability._id}
            className="cursor-pointer rounded bg-secondary p-5 shadow transition hover:opacity-80"
            onClick={(e) => {
              if (!showMenu) {
                handleClick(availability._id);
              }
            }}
          >
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-md font-semibold">
                  {availability.title}
                  {availability.isDefault && (
                    <span className="ml-2 rounded bg-green-700 px-2 py-0.5 text-sm">
                      Default
                    </span>
                  )}
                </h2>
              </div>
              <div className="relative" ref={menuRef}>
                <BsThreeDotsVertical
                  className="cursor-pointer text-lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowMenu(availability._id);
                  }}
                />
                {showMenu === availability._id && (
                  <div className="absolute right-0 mt-2 w-32 rounded bg-primary shadow-lg">
                    <button
                      className="block w-full px-4 py-2 text-left text-sm text-white hover:opacity-80"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAvailabilityToDelete(availability._id);
                        setShowDeletePopup(true);
                        setShowMenu(null);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-2 space-y-1">
              {formatDayTimes(availability.days)
                .split(" | ")
                .map((dayTimes, index) => (
                  <p key={index} className="text-[15px]">
                    {dayTimes}
                  </p>
                ))}
            </div>
            <div className="mt-2 flex items-center text-sm text-[#ffffff8c]">
              <FaGlobe className="mr-1" /> {availability.timezone}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Availability;
