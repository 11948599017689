import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import {
  fetchPsychologistProfile,
  savePsychologistProfile,
  updatePsychologistProfile,
  uploadPsychologistProfileImage,
  uploadPsychologistCertificate,
  resetProfile,
} from "../../redux/slices/PsychologistProfileSlice";
import { toast } from "react-hot-toast";
import profileDefaultImage from "../../assets/profile-default.png";
import LoadingScreen from "../LoadingScreen";
import { MultiSelect, Option } from "react-multi-select-component";
import ISO6391 from "iso-639-1";
import { X } from "react-feather";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const { REACT_APP_S3_URL } = process.env;

const PsychologistProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const profileState = useSelector(
    (state: RootState) => state.psychologistProfile,
  );
  const [name, setName] = useState(profileState.name || "");
  const [uploading, setUploading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [profileImageUrl, setProfileImageUrl] =
    useState<string>(profileDefaultImage);
  const [title, setTitle] = useState(profileState.title || "");
  const [experience, setExperience] = useState<string>("");
  const [newExpertise, setNewExpertise] = useState("");
  const [languagesList, setLanguagesList] = useState<string[]>(
    profileState.languages || [],
  );
  const [hasChanges, setHasChanges] = useState(false);
  const [addingCertificate, setAddingCertificate] = useState(false);
  const [bio, setBio] = useState(profileState.bio || "");
  const [expertiseList, setExpertiseList] = useState<string[]>(
    profileState.expertise || [],
  );
  const [certificates, setCertificates] = useState<
    { name: string; file: File | null; url?: string }[]
  >(
    profileState.certificates
      ? profileState.certificates.map((cert) => ({ ...cert, file: null }))
      : [],
  );
  const charLimit = 300;
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [bio]);

  useEffect(() => {
    dispatch(fetchPsychologistProfile()).then((action) => {
      if (fetchPsychologistProfile.fulfilled.match(action)) {
        const data = action.payload;
        const profileData = data.newChanges ? data.newChanges : data;
        setName(profileData.name);
        setTitle(profileData.title);
        setExperience(profileData.experience);
        setLanguagesList(profileData.languages);
        setBio(profileData.bio);
        setExpertiseList(profileData.expertise);
        setCertificates(
          profileData.certificates.map(
            (cert: { name: string; url: string }) => ({
              ...cert,
              file: null,
            }),
          ),
        );
        setProfileImageUrl(
          `${REACT_APP_S3_URL}/uploads/psychologists/profile/${profileData.image}`,
        );
      }
      setLoadingData(false);
    });
    return () => {
      dispatch(resetProfile());
    };
  }, [dispatch]);

  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setProfileImage(file);
      setProfileImageUrl(URL.createObjectURL(file));
      setUploading(true);
      toast.loading("Uploading image...");

      dispatch(uploadPsychologistProfileImage(file)).then((action) => {
        toast.dismiss();
        if (uploadPsychologistProfileImage.fulfilled.match(action)) {
          const profileImageUrl = `${process.env.REACT_APP_S3_URL}/uploads/psychologists/profile/${action.payload}`;
          sessionStorage.setItem(
            "psychologistProfileImageUrl",
            profileImageUrl,
          );
          setProfileImageUrl(profileImageUrl);
          toast.success("Profile image uploaded");
        } else {
          toast.error("Failed to upload profile image");
        }
        setUploading(false);
      });
    }
  };

  const languageOptions = ISO6391.getAllCodes().map((code) => ({
    label: ISO6391.getName(code),
    value: code,
  }));

  const handleLanguagesChange = (selected: Option[]) => {
    setLanguagesList(selected.map((option: Option) => option.value));
  };

  const handleAddCertificate = () => {
    setAddingCertificate(true);
    setCertificates([...certificates, { name: "", file: null, url: "" }]);
  };

  const handleCertificateChange = (
    index: number,
    field: string,
    value: string | File | null,
  ) => {
    setHasChanges(true);
    const updatedCertificates = certificates.map((certificate, i) =>
      i === index ? { ...certificate, [field]: value } : certificate,
    );
    setCertificates(updatedCertificates);

    if (field === "file" && value instanceof File) {
      setUploading(true);

      toast.loading("Uploading certificate...");

      dispatch(uploadPsychologistCertificate(value)).then((action) => {
        toast.dismiss();

        if (uploadPsychologistCertificate.fulfilled.match(action)) {
          const certificateUrl = `${process.env.REACT_APP_S3_URL}/uploads/psychologists/certificates/${action.payload}`;
          const updatedCertificatesWithUrl = updatedCertificates.map(
            (certificate, i) =>
              i === index
                ? { ...certificate, url: action.payload }
                : certificate,
          );
          setCertificates(updatedCertificatesWithUrl);
          toast.success(
            "Certificate uploaded successfully. Please save your changes.",
          );
        } else {
          toast.error("Failed to upload certificate");
        }
        setUploading(false);
      });
    }
  };
  const handleRemoveCertificate = (index: number) => {
    setHasChanges(true);
    setCertificates(certificates.filter((_, i) => i !== index));
  };

  const handleRemoveField = (type: string, index: number) => {
    setHasChanges(true);
    if (type === "language") {
      setLanguagesList(languagesList.filter((_, i) => i !== index));
    } else if (type === "expertise") {
      setExpertiseList(expertiseList.filter((_, i) => i !== index));
    } else if (type === "certificate") {
      setCertificates(certificates.filter((_, i) => i !== index));
    }
  };

  const handleAddExpertise = () => {
    if (newExpertise.trim()) {
      setExpertiseList([...expertiseList, newExpertise.trim()]);
      setNewExpertise("");
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddExpertise();
    }
  };

  const handleSubmit = () => {
    if (
      !name ||
      !title ||
      !experience ||
      !languagesList.length ||
      !bio ||
      !expertiseList.length
    ) {
      toast.error("All fields are required");
      return;
    }

    const validCertificates = certificates.filter(
      (cert) => cert.name && cert.url,
    );

    const profileData = {
      name,
      image:
        profileState.profileImageFileName ||
        sessionStorage.getItem("psychologistProfileImageFileName"),
      title,
      experience,
      languages: languagesList,
      bio,
      expertise: expertiseList,
      certificates: validCertificates.map((certificate) => ({
        name: certificate.name,
        url: certificate.url?.split("/").pop(),
      })),
    };

    const actionCreator = profileState.name
      ? updatePsychologistProfile
      : savePsychologistProfile;

    dispatch(actionCreator(profileData)).then((action) => {
      if (actionCreator.fulfilled.match(action)) {
        toast.success("Profile saved successfully");
      } else {
        toast.error("Failed to save profile");
      }
    });
  };

  if (loadingData) {
    return (
      <p className="mt-32">
        <LoadingScreen />
      </p>
    );
  }

  return (
    <div className="m-auto my-10 w-[60%] rounded-xl border border-primary">
      <div>
        <div className="flex items-center gap-x-3 px-6 pt-5  text-white text-opacity-90">
          <button onClick={() => navigate(-1)} className="text-lg">
            <FaArrowLeft />
          </button>
          <p className="text-2xl font-semibold ">Psychologist Profile</p>
        </div>
        <p className="mt-2 border-b border-primary px-6 pb-5 font-medium text-light text-opacity-45">
          Manage Psychologist Profile here
        </p>
      </div>
      <div className="mb-4 flex flex-col items-center">
        <div className="relative mb-4 mt-8">
          <img
            src={profileImageUrl}
            alt="Profile"
            className="h-32 w-32 rounded-full border-2 border-primary object-cover"
          />
          <input
            type="file"
            onChange={handleProfileImageChange}
            className="hidden"
            id="profileImageUpload"
          />
        </div>
        <button
          onClick={() => document.getElementById("profileImageUpload")?.click()}
          className="mt-4 flex items-center rounded bg-[#fbfafb] px-4 py-2 text-[15px] font-semibold text-primary hover:opacity-80"
        >
          {profileState.name
            ? "Upload New Profile Picture"
            : "Upload Profile Picture"}
        </button>
      </div>
      <div className="px-10">
        <div className="mb-4">
          <label className="mb-2 block font-semibold">
            Full Name <span className="text-red-500"> *</span>
          </label>
          <input
            type="text"
            value={name}
            placeholder="John Doe"
            onChange={(e) => setName(e.target.value)}
            className="mb-2 w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-placeholder focus:border-[#535353] focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="mb-2 block font-semibold">
            Title <span className="text-red-500"> *</span>
          </label>
          <input
            type="text"
            value={title}
            placeholder="Clinical Psychologist"
            onChange={(e) => setTitle(e.target.value)}
            className="mb-2 w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-placeholder focus:border-[#535353] focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="mb-2 block font-semibold">
            Experience (Years) <span className="text-red-500"> *</span>
          </label>
          <input
            type="text"
            value={experience}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setExperience(value);
              }
            }}
            placeholder="5"
            className="mb-2 w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-placeholder focus:border-[#535353] focus:outline-none"
          />
        </div>

        <div className="mb-4">
          <label className="mb-2 block font-semibold">
            Languages <span className="text-red-500"> *</span>
          </label>
          <MultiSelect
            options={languageOptions}
            value={languageOptions.filter((option) =>
              languagesList.includes(option.value),
            )}
            onChange={handleLanguagesChange}
            labelledBy="Select Languages"
            hasSelectAll={false}
            className="rounded-md border border-primary"
          />
        </div>
        <div className="mb-4">
          <div className="flex items-center justify-between">
            <label className="my-2 block font-semibold">
              Bio <span className="text-red-500"> *</span>
            </label>
            <div className="mt-2 text-right text-sm text-light text-opacity-80">
              {bio.length}/{charLimit}
            </div>
          </div>
          <textarea
            ref={textareaRef}
            value={bio}
            onChange={(e) => {
              if (e.target.value.length <= charLimit) {
                setBio(e.target.value);
              }
            }}
            placeholder="Write a brief description about yourself"
            className="scrollbar h-auto min-h-20 w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-placeholder focus:border-[#535353] focus:outline-none"
          />
        </div>
        <div className="mb-6">
          <label className="mb-2 block font-semibold">
            Expertise <span className="text-red-500"> *</span>
          </label>
          <div className="flex max-w-[620px] flex-wrap gap-2">
            {expertiseList.map((expertise, index) => (
              <div
                key={index}
                className="flex items-center space-x-2 rounded border border-primary bg-[#1a1b1b] px-3 py-1 text-light"
              >
                <span>{expertise}</span>
                <button
                  onClick={() => handleRemoveField("expertise", index)}
                  className="text-red-500 hover:text-red-700"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
          <div className="mt-4 flex">
            <input
              type="text"
              value={newExpertise}
              onChange={(e) => setNewExpertise(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="e.g., Cognitive Behavioral Therapy"
              className="flex-grow rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-placeholder focus:border-[#535353] focus:outline-none"
            />
            <button
              onClick={handleAddExpertise}
              className="ml-4 flex items-center rounded bg-[#fbfafb] px-4 py-2 text-[15px] font-semibold text-primary hover:opacity-80"
            >
              Add
            </button>
          </div>
        </div>
        <div className="mb-4">
          <label className="mb-2 block font-semibold">
            Certificates <span className="text-red-500"> *</span>
          </label>
          <div className="flex flex-wrap  gap-x-6 gap-y-2">
            {certificates.map((certificate, index) =>
              certificate.url ? (
                <div
                  key={index}
                  className="mb-2 flex  max-w-[620px] items-center space-x-2"
                >
                  <a
                    href={`${REACT_APP_S3_URL}/uploads/psychologists/certificates/${certificate.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light underline hover:text-opacity-80"
                  >
                    {certificate.name}
                  </a>
                  <button
                    onClick={() => handleRemoveCertificate(index)}
                    className="mt-1 text-red-500 hover:text-red-700"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
              ) : (
                addingCertificate &&
                index === certificates.length - 1 && (
                  <div key={index} className="mb-2 flex items-center space-x-2">
                    <input
                      type="text"
                      value={certificate.name}
                      onChange={(e) =>
                        handleCertificateChange(index, "name", e.target.value)
                      }
                      placeholder="Certificate Name"
                      className="w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                    />
                    <input
                      type="file"
                      onChange={(e) =>
                        handleCertificateChange(
                          index,
                          "file",
                          e.target.files ? e.target.files[0] : null,
                        )
                      }
                      className="w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-1.5 text-light placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                    />
                    <button
                      onClick={() => handleRemoveCertificate(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                )
              ),
            )}
          </div>
          <button
            onClick={handleAddCertificate}
            className="mt-4 flex w-fit items-center rounded bg-[#fbfafb] px-4 py-2 text-[15px] font-semibold text-primary hover:opacity-80"
          >
            <img
              src="../../assets/plus.png"
              alt="plus"
              className="mr-3 h-3 w-3"
            />
            <span>Add Certificate</span>
          </button>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={handleSubmit}
            className={`mb-8 flex w-fit items-center justify-center rounded bg-[#fbfafb] px-4 py-2 text-[15px] font-semibold text-primary hover:opacity-80 ${uploading ? "cursor-not-allowed opacity-50" : ""}`}
            disabled={uploading}
          >
            {hasChanges
              ? "Update Changes"
              : profileState.name
                ? "Update"
                : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PsychologistProfile;
