import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  FaUserCircle,
  FaUsers,
  FaClipboardList,
  FaUserMd,
  FaCaretDown,
  FaCalendarAlt,
  FaUserAstronaut,
  FaCalendarCheck,
  FaCalendarDay,
  FaCalendarPlus,
  FaBoxOpen,
  FaGoogle,
} from "react-icons/fa";
import { RootState } from "../redux/store";
import ProfileDropdown from "./ProfileDropdown";
import { checkTokenValidity } from "../api/api";
import { setUser } from "../redux/slices/adminAuthSlice";

const { REACT_APP_S3_URL } = process.env;

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [employeesOpen, setEmployeesOpen] = useState(false);
  const [counsellorsOpen, setCounsellorsOpen] = useState(false);
  const [calendarXOpen, setCalendarXOpen] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const { user } = useSelector((state: RootState) => state.adminAuth);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const userData = await checkTokenValidity(token);
          dispatch(setUser(userData.data));
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, [dispatch]);

  useEffect(() => {
    if (user && initialLoad) {
      if (
        location.pathname === "/dashboard" ||
        location.pathname === "/dashboard/"
      ) {
        if (user.role === "superAdmin") {
          setEmployeesOpen(true);
          setCounsellorsOpen(false);
          setCalendarXOpen(false);
          navigate("/dashboard/employees/users");
        } else if (user.role === "psychologist") {
          setCalendarXOpen(true);
          setEmployeesOpen(false);
          setCounsellorsOpen(false);
          navigate("/dashboard/calendarX/profile");
        }
      }
      setInitialLoad(false);
    }
  }, [user, navigate, initialLoad, location.pathname]);

  const toggleEmployees = () => {
    setEmployeesOpen((prev) => {
      if (!prev) {
        setCounsellorsOpen(false);
        setCalendarXOpen(false);
        navigate("/dashboard/employees/users");
      }
      return !prev;
    });
  };

  const toggleCounsellors = () => {
    setCounsellorsOpen((prev) => {
      if (!prev) {
        setEmployeesOpen(false);
        setCalendarXOpen(false);
        navigate("/dashboard/psychologists/users");
      }
      return !prev;
    });
  };

  const toggleCalendarX = () => {
    setCalendarXOpen((prev) => {
      if (!prev) {
        setEmployeesOpen(false);
        setCounsellorsOpen(false);
        navigate("/dashboard/calendarX/profile");
      }
      return !prev;
    });
  };

  return (
    <aside className="flex h-full w-80 flex-col border-r border-primary bg-[#1e1e1e] text-light">
      <div className="h-16 border-b border-primary px-4 py-2 text-left">
        <img
          src={`${REACT_APP_S3_URL}/assets/one-life-logo-white.png`}
          alt="Logo"
          className="h-12"
        />
      </div>
      <nav className="flex-grow px-4 py-6">
        {user?.role === "superAdmin" && (
          <>
            <div className="mb-2">
              <button
                onClick={toggleEmployees}
                className="mb-2 flex w-full items-center justify-between rounded p-2 text-left hover:bg-[#2b2b2b70]"
              >
                <div className="flex items-center">
                  <FaUsers className="mr-2" size={24} />
                  <h2 className="text-[17px] font-semibold">Employees</h2>
                </div>
                <FaCaretDown />
              </button>
              {employeesOpen && (
                <ul className="space-y-2 pl-6">
                  <li>
                    <NavLink
                      to="/dashboard/employees/users"
                      className={({ isActive }) =>
                        isActive
                          ? "block rounded bg-[#2b2b2b] p-2"
                          : "block rounded p-2 hover:bg-[#2b2b2b70]"
                      }
                    >
                      <div className="flex items-center">
                        <FaUserCircle className="mr-2" size={20} />
                        Users
                      </div>
                    </NavLink>
                  </li>
                </ul>
              )}
              {employeesOpen && (
                <ul className="space-y-2 pl-6">
                  <li>
                    <NavLink
                      to="/dashboard/google-oauth"
                      className={({ isActive }) =>
                        isActive
                          ? "block rounded bg-[#2b2b2b] p-2"
                          : "block rounded p-2 hover:bg-[#2b2b2b70]"
                      }
                    >
                      <div className="flex items-center">
                        <FaGoogle className="mr-2" size={20} />
                        Google OAuth
                      </div>
                    </NavLink>
                  </li>
                </ul>
              )}
            </div>
            <div>
              <button
                onClick={toggleCounsellors}
                className="mb-2 flex w-full items-center justify-between rounded p-2 text-left hover:bg-[#2b2b2b70]"
              >
                <div className="flex items-center">
                  <FaUserMd className="mr-2" size={20} />
                  <h2 className="text-[17px] font-semibold">Psychologists</h2>
                </div>
                <FaCaretDown />
              </button>
              {counsellorsOpen && (
                <ul className="space-y-2 pl-6">
                  <li>
                    <NavLink
                      to="/dashboard/psychologists/users"
                      className={({ isActive }) =>
                        isActive
                          ? "block rounded bg-[#2b2b2b] p-2"
                          : "block rounded p-2 hover:bg-[#2b2b2b70]"
                      }
                    >
                      <div className="flex items-center">
                        <FaUserCircle className="mr-2" size={20} />
                        Users
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/psychologists/requests"
                      className={({ isActive }) =>
                        isActive
                          ? "block rounded bg-[#2b2b2b] p-2"
                          : "block rounded p-2 hover:bg-[#2b2b2b70]"
                      }
                    >
                      <div className="flex items-center">
                        <FaClipboardList className="mr-2" size={20} />
                        Requests
                      </div>
                    </NavLink>
                  </li>
                </ul>
              )}
            </div>
          </>
        )}
        {(user?.role === "superAdmin" || user?.role === "psychologist") && (
          <div>
            <button
              onClick={toggleCalendarX}
              className="mb-2 flex w-full items-center justify-between rounded p-2 text-left hover:bg-[#2b2b2b70]"
            >
              <div className="flex items-center">
                <FaCalendarAlt className="mr-2" size={20} />
                <h2 className="text-[17px] font-semibold">Calendar-X</h2>
              </div>
              <FaCaretDown />
            </button>
            {calendarXOpen && (
              <ul className="space-y-2 pl-6">
                <li>
                  <NavLink
                    to="/dashboard/calendarX/profile"
                    className={({ isActive }) =>
                      isActive
                        ? "block rounded bg-[#2b2b2b] p-2"
                        : "block rounded p-2 hover:bg-[#2b2b2b70]"
                    }
                  >
                    <div className="flex items-center">
                      <FaUserAstronaut className="mr-2" size={20} />
                      Calendar-X Profile
                    </div>
                  </NavLink>
                </li>
                {user?.role === "psychologist" && (
                  <li>
                    <NavLink
                      to="/dashboard/calendarX/psychologist-profile"
                      className={({ isActive }) =>
                        isActive
                          ? "block rounded bg-[#2b2b2b] p-2"
                          : "block rounded p-2 hover:bg-[#2b2b2b70]"
                      }
                    >
                      <div className="flex items-center">
                        <FaUserMd className="mr-2" size={20} />
                        Psychologist Profile
                      </div>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    to="/dashboard/calendarX/availability"
                    className={({ isActive }) =>
                      isActive
                        ? "block rounded bg-[#2b2b2b] p-2"
                        : "block rounded p-2 hover:bg-[#2b2b2b70]"
                    }
                  >
                    <div className="flex items-center">
                      <FaCalendarCheck className="mr-2" size={20} />
                      Availability
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dashboard/calendarX/events"
                    className={({ isActive }) =>
                      isActive
                        ? "block rounded bg-[#2b2b2b] p-2"
                        : "block rounded p-2 hover:bg-[#2b2b2b70]"
                    }
                  >
                    <div className="flex items-center">
                      <FaCalendarDay className="mr-2" size={20} />
                      Events
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dashboard/calendarX/bookings"
                    className={({ isActive }) =>
                      isActive
                        ? "block rounded bg-[#2b2b2b] p-2"
                        : "block rounded p-2 hover:bg-[#2b2b2b70]"
                    }
                  >
                    <div className="flex items-center">
                      <FaCalendarPlus className="mr-2" size={20} />
                      Bookings
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
        )}
        {user?.role === "superAdmin" && (
          <div className="mb-2">
            <NavLink
              to="/dashboard/product"
              className={({ isActive }) =>
                isActive
                  ? "block rounded bg-[#2b2b2b] p-2"
                  : "block rounded p-2 hover:bg-[#2b2b2b70]"
              }
            >
              <div className="flex items-center">
                <FaBoxOpen className="mr-2" size={20} />
                <h2 className="text-[17px] font-semibold">Product</h2>
              </div>
            </NavLink>
          </div>
        )}
      </nav>
      <div className="relative flex items-center justify-between border-t border-primary bg-[#2b2b2b70] px-4 py-3">
        <div className="flex items-center space-x-2">
          <div>
            <FaUserCircle size={36} />
          </div>
          <div className="flex flex-col">
            <span className="text-[17px] font-semibold">{user?.fullName}</span>
            <span className="text-sm opacity-70">{user?.email}</span>
          </div>
        </div>
        <ProfileDropdown />
      </div>
    </aside>
  );
};

export default Sidebar;
