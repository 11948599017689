import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { RootState, AppDispatch } from "../redux/store";
import { fetchProfile } from "../redux/slices/adminAuthSlice";
import ClipLoader from "react-spinners/ClipLoader";

const DashboardLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { token, loading } = useSelector((state: RootState) => state.adminAuth);
  const [timeoutExpired, setTimeoutExpired] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(fetchProfile(token));
    }

    const timer = setTimeout(() => {
      setTimeoutExpired(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, [token, dispatch]);

  if (loading && !timeoutExpired) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="flex h-screen w-screen items-center justify-center bg-[#161616]">
          <ClipLoader color="#808080" size={50} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex flex-grow flex-col">
        <main className="layout-scrollbar flex-grow overflow-y-auto bg-primary text-light">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
