import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../redux/store";
import {
  fetchPsychologists,
  createProduct,
  fetchProductsByPsychologistId,
  activateProduct,
  deleteProduct,
  updateProduct,
  Product,
} from "../redux/slices/productSlice";
import { FaArrowLeft, FaExclamationCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../styles/Scrollbar.css";
import CustomSelect from "./CustomSelect";
import LoadingScreen from "./LoadingScreen";
import { clearProducts } from "../redux/slices/productSlice";

const ProductPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { psychologists, products, loading, error } = useSelector(
    (state: RootState) => state.product,
  );
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [pricingIN, setPricingIN] = useState(0);
  const [pricingUS, setPricingUS] = useState(0);
  const [isFree, setIsFree] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [description, setDescription] = useState("");
  const [psychologistId, setPsychologistId] = useState("");
  const [noOfSessions, setNoOfSessions] = useState(0);
  const [type, setType] = useState("counselling-session");
  const [durationInMinutes, setDurationInMinutes] = useState(60);
  const [selectedPsychologistId, setSelectedPsychologistId] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [productToDelete, setProductToDelete] = useState<string | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const [loadingData, setLoadingData] = useState(true);
  const [editProduct, setEditProduct] = useState<Product | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const charLimit = 180;
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [description]);

  useEffect(() => {
    dispatch(fetchPsychologists());
    setLoadingData(false);
  }, [dispatch]);

  useEffect(() => {
    setSelectedPsychologistId("");
    dispatch(clearProducts());
  }, [dispatch]);

  useEffect(() => {
    if (selectedPsychologistId) {
      dispatch(fetchProductsByPsychologistId(selectedPsychologistId));
    }
  }, [dispatch, selectedPsychologistId]);

  useEffect(() => {
    if (psychologists.length > 0) {
      setSelectedPsychologistId(psychologists[0]._id);
      setPsychologistId(psychologists[0]._id);
    }
  }, [psychologists]);

  const handleCreateProduct = (event: React.FormEvent) => {
    event.preventDefault();

    if (
      !title ||
      !pricingIN ||
      !pricingUS ||
      !description ||
      !psychologistId ||
      !noOfSessions ||
      !type ||
      !durationInMinutes
    ) {
      setFormError("All fields are required");
      return;
    }

    const product = {
      title,
      pricing: {
        IN: pricingIN,
        US: pricingUS,
        isFree,
        discount,
      },
      description,
      psychologistId,
      noOfSessions,
      type,
      durationInMinutes,
    };

    dispatch(createProduct(product));
    setShowModal(false);
    setFormError(null);
  };

  const handleEditProduct = (event: React.FormEvent) => {
    event.preventDefault();

    if (
      !editProduct ||
      !title ||
      !pricingIN ||
      !pricingUS ||
      !description ||
      !psychologistId ||
      !noOfSessions ||
      !type ||
      !durationInMinutes
    ) {
      setFormError("All fields are required");
      return;
    }

    const updatedProduct = {
      ...editProduct,
      title,
      pricing: {
        IN: pricingIN,
        US: pricingUS,
        isFree,
        discount,
      },
      description,
      psychologistId,
      noOfSessions,
      type,
      durationInMinutes,
    };

    dispatch(updateProduct(updatedProduct));
    setEditProduct(null);
    setShowModal(false);
    setFormError(null);
  };

  const handleViewProduct = (product: Product) => {
    setEditProduct(product);
    setTitle(product.title);
    setPricingIN(product.pricing.IN);
    setPricingUS(product.pricing.US);
    setIsFree(product.pricing.isFree);
    setDiscount(product.pricing.discount);
    setDescription(product.description);
    setPsychologistId(product.psychologistId);
    setNoOfSessions(product.noOfSessions);
    setType(product.type);
    setDurationInMinutes(product.durationInMinutes);
    setShowModal(true);
  };

  const handleActivateProduct = (productId: string) => {
    dispatch(activateProduct(productId));
  };

  const confirmDeleteProduct = (productId: string) => {
    setProductToDelete(productId);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    if (productToDelete) {
      dispatch(deleteProduct(productToDelete));
      setShowDeleteConfirm(false);
      setProductToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirm(false);
    setProductToDelete(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTitle("");
    setPricingIN(0);
    setPricingUS(0);
    setIsFree(false);
    setDiscount(0);
    setDescription("");
    setPsychologistId("");
    setNoOfSessions(0);
    setType("counselling-session");
    setDurationInMinutes(60);
    setEditProduct(null);
  };

  if (loadingData) {
    return (
      <p className="mt-32">
        <LoadingScreen />
      </p>
    );
  }

  return (
    <>
      <div className="flex h-[63px] items-center gap-x-4 px-6 text-white text-opacity-90">
        <button onClick={() => navigate(-1)} className="text-lg">
          <FaArrowLeft />
        </button>
        <h1 className="text-2xl font-semibold">Product</h1>
      </div>{" "}
      <div className="border-b border-primary"></div>
      <div className="container mx-auto p-6">
        <div className="flex items-center justify-between">
          <div className="mb-6 w-80">
            <CustomSelect
              name="psychologist"
              value={selectedPsychologistId}
              onChange={(value) => setSelectedPsychologistId(value)}
              options={psychologists.map((psychologist) => ({
                _id: psychologist._id,
                title: psychologist.name,
              }))}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              placeholder="Select psychologist"
            />
          </div>
          <button
            onClick={() => {
              setShowModal(true);
              setEditProduct(null);
            }}
            className="flex items-center rounded bg-[#fbfafb] px-4 py-2 text-[15px] font-semibold text-primary hover:opacity-80"
          >
            <img
              src="../../assets/plus.png"
              alt="plus"
              className="mr-3 h-3 w-3"
            />
            Create Product
          </button>
        </div>
        {showModal && (
          <div>
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="fixed inset-0 my-auto flex items-center justify-center overflow-y-auto bg-opacity-50">
              <div className="scrollbar max-h-[80vh] w-2/5 overflow-y-auto rounded bg-[#181818] p-6 shadow-lg">
                <h2 className="mb-8 text-xl font-bold">
                  {editProduct ? "Edit Product" : "Create Product"}
                </h2>
                {formError && <p className="text-red-500">{formError}</p>}
                <form
                  onSubmit={
                    editProduct ? handleEditProduct : handleCreateProduct
                  }
                >
                  <div className="mb-4">
                    <label className="my-2 block font-semibold">
                      Title <span className="text-red-500"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Enter title")}
                      className="w-full rounded-md border border-primary bg-[#181818] px-3 py-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="my-2 block font-semibold">
                      Price (in ₹) <span className="text-red-500"> *</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter pricing in INR"
                      value={pricingIN}
                      onChange={(e) => setPricingIN(Number(e.target.value))}
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "Enter pricing in INR")
                      }
                      className="w-full rounded-md border border-primary bg-[#181818] px-3 py-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="my-2 block font-semibold">
                      Price (in $) <span className="text-red-500"> *</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter pricing in USD"
                      value={pricingUS}
                      onChange={(e) => setPricingUS(Number(e.target.value))}
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "Enter pricing in USD")
                      }
                      className="w-full rounded-md border border-primary bg-[#181818] px-3 py-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="mb-2 block">Is Free</label>
                    <input
                      type="checkbox"
                      checked={isFree}
                      onChange={(e) => setIsFree(e.target.checked)}
                      className="mt-1"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="my-2 block font-semibold">
                      Discount <span className="text-red-500"> *</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter discount"
                      value={discount}
                      onChange={(e) => setDiscount(Number(e.target.value))}
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Enter discount")}
                      className="w-full rounded-md border border-primary bg-[#181818] px-3 py-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="flex items-center justify-between">
                      <label className="my-2 block font-semibold">
                        Description <span className="text-red-500"> *</span>
                      </label>
                      <div className="mt-2 text-right text-sm text-light text-opacity-80">
                        {description.length}/{charLimit}
                      </div>
                    </div>
                    <textarea
                      ref={textareaRef}
                      value={description}
                      onChange={(e) => {
                        if (e.target.value.length <= charLimit) {
                          setDescription(e.target.value);
                        }
                      }}
                      placeholder="Write a brief description about the product"
                      className="scrollbar h-auto min-h-20 w-full rounded-md border border-primary bg-[#181818] px-3 py-2 text-light placeholder:text-placeholder focus:border-[#535353] focus:outline-none"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="mb-2 block font-semibold">
                      Psychologist <span className="text-red-500"> *</span>
                    </label>
                    <select
                      value={psychologistId}
                      onChange={(e) => setPsychologistId(e.target.value)}
                      className="w-full rounded-md border border-primary bg-[#181818] px-3 py-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                      required
                    >
                      <option value="">Select Psychologist</option>
                      {psychologists.map((psychologist) => (
                        <option key={psychologist._id} value={psychologist._id}>
                          {psychologist.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="my-2 block font-semibold">
                      Number of Sessions{" "}
                      <span className="text-red-500"> *</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter number of sessions"
                      value={noOfSessions}
                      onChange={(e) => setNoOfSessions(Number(e.target.value))}
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "Enter number of sessions")
                      }
                      className="w-full rounded-md border border-primary bg-[#181818] px-3 py-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="my-2 block font-semibold">
                      Type <span className="text-red-500"> *</span>
                    </label>
                    <select
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      className="w-full rounded-md border border-primary bg-[#181818] px-3 py-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                      required
                    >
                      <option value="counselling-session">
                        Counselling Session
                      </option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="my-2 block font-semibold">
                      Duration in Minutes{" "}
                      <span className="text-red-500"> *</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter duration in minutes"
                      value={durationInMinutes}
                      onChange={(e) =>
                        setDurationInMinutes(Number(e.target.value))
                      }
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "Enter duration in minutes")
                      }
                      className="w-full rounded-md border border-primary bg-[#181818] px-3 py-2 placeholder:text-tertiary focus:border-[#535353] focus:outline-none"
                      required
                    />
                  </div>
                  <div className="flex justify-end space-x-4">
                    <button
                      type="button"
                      onClick={handleCloseModal}
                      className="mr-2 rounded px-4 py-2 font-medium hover:opacity-80"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="mb-2 mt-4 rounded bg-light px-4 py-2 font-medium text-primary hover:opacity-80"
                    >
                      {editProduct ? "Update" : "Create"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {error && <p className="text-red-500">{JSON.stringify(error)}</p>}

        {!loading && selectedPsychologistId === "" && (
          <p>Please select a psychologist to view their products.</p>
        )}

        {products.length === 0 && !loading && selectedPsychologistId && (
          <p>No products found for the selected psychologist.</p>
        )}

        {loading && selectedPsychologistId ? (
          <p className="mb-4">Searching...</p>
        ) : (
          <>
            {products.length > 0 && (
              <table className="mt-2 min-w-full border border-primary bg-[#181818] text-left">
                <thead className="bg-secondary">
                  <tr>
                    <th className="border-b border-primary px-4 py-2">Title</th>
                    <th className="border-b border-primary px-4 py-2">
                      Status
                    </th>
                    <th className="border-b border-primary px-4 py-2">
                      No. of Sessions
                    </th>
                    <th className="border-b border-primary px-4 py-2">Type</th>
                    <th className="border-b border-primary px-4 py-2">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product: Product) => (
                    <tr
                      key={product._id}
                      className="cursor-pointer hover:bg-[#1e1e1e38]"
                    >
                      <td className="border-b border-primary px-4 py-2">
                        {product.title}
                      </td>
                      <td className="border-b border-primary px-4 py-2">
                        {product.active ? "Active" : "Inactive"}
                      </td>
                      <td className="border-b border-primary px-4 py-2">
                        {product.noOfSessions}
                      </td>
                      <td className="border-b border-primary px-4 py-2">
                        {product.type}
                      </td>
                      <td className="flex items-center justify-start gap-x-6 border-b border-primary px-4 py-2">
                        <button
                          onClick={() => handleViewProduct(product)}
                          className="text-blue-500 hover:underline"
                        >
                          View
                        </button>
                        <button
                          onClick={() =>
                            product._id && confirmDeleteProduct(product._id)
                          }
                          className="ml-2 cursor-pointer text-red-500 hover:text-red-600"
                        >
                          Delete
                        </button>
                        {!product.active && (
                          <button
                            onClick={() =>
                              product._id && handleActivateProduct(product._id)
                            }
                            className="ml-2 text-green-500 hover:underline"
                          >
                            Activate
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
        {showDeleteConfirm && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="rounded bg-[#181818] p-6 shadow-lg">
              <div className="mb-2 flex items-center gap-x-2">
                <FaExclamationCircle size={24} className="text-red-700" />
                <h2 className="text-xl font-bold text-white">Confirm Delete</h2>
              </div>
              <p>Are you sure you want to delete this product?</p>
              <div className="mt-4 flex justify-end space-x-4">
                <button
                  onClick={handleCancelDelete}
                  className="mr-2 px-4 py-2 font-medium hover:opacity-80"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmDelete}
                  className="rounded bg-[#fbfafb] px-4 py-2 font-medium text-primary hover:opacity-80"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductPage;
