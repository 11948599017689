import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import LoadingScreen from "./LoadingScreen";

const { REACT_APP_API_URL } = process.env;

interface ConnectionStatus {
  isConnected: boolean;
}

const ConnectGoogleAccount: React.FC = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [status, setStatus] = useState<ConnectionStatus | null>(null);
  const token = useSelector((state: RootState) => state.adminAuth.token);

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}/v1/admin/google-oauth-integration/status`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setStatus(response.data.data);
        setLoadingData(false);
      })
      .catch((error) => {
        setLoadingData(false);
      });
  }, [token]);

  const handleConnect = async () => {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}/v1/admin/google-oauth-integration/auth-url`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    console.log(data.data.authUrl);

    window.open(data.data.authUrl, "_blank");
  };

  if (loadingData) {
    return (
      <p className="mt-32">
        <LoadingScreen />
      </p>
    );
  }

  // if (error) {
  //     return (
  //         <div className="flex items-center justify-center min-h-screen">
  //             <div className="p-6 rounded shadow-md">
  //                 <h2 className="text-2xl font-bold mb-4">Error</h2>
  //                 <p>{error}</p>
  //             </div>
  //         </div>
  //     );
  // }

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="rounded p-6 text-center">
        {status?.isConnected ? (
          <>
            <h2 className="mb-4 text-2xl font-bold">
              Google Account Connected
            </h2>
            <p>Your Google account is successfully connected.</p>
          </>
        ) : (
          <>
            <h2 className="mb-4 text-2xl font-bold">Connect Google Account</h2>
            <p>To use this feature, you need to connect your Google account.</p>
            <button
              className="mt-6 rounded bg-light px-4 py-2 font-medium text-primary hover:opacity-80"
              onClick={handleConnect}
            >
              Connect Google Account
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ConnectGoogleAccount;
