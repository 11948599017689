import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchProfileChangeRequests,
  approveProfileChange,
} from "../../api/psychologistProfileRequestApi";
import { RootState } from "../store";

interface PsychologistProfileChangeRequest {
  _id: string;
  newChanges: {
    bio: string;
    experience: string;
    expertise: string[];
    image: string;
    languages: string[];
    name: string;
    title: string;
    certificates: { name: string; url: string }[];
  };
}

interface PsychologistProfileChangeRequestsState {
  requests: PsychologistProfileChangeRequest[];
  loading: boolean;
  error: string | null;
  approving: boolean;
}

const initialState: PsychologistProfileChangeRequestsState = {
  requests: [],
  loading: false,
  error: null,
  approving: false,
};

export const getProfileChangeRequests = createAsyncThunk(
  "profileChangeRequests/getAll",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    return await fetchProfileChangeRequests(token);
  },
);

export const approveProfileChangeRequest = createAsyncThunk(
  "profileChangeRequests/approve",
  async (calendarXUserId: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = state.adminAuth.token;
    if (!token) {
      return rejectWithValue("Token is missing");
    }
    return await approveProfileChange(calendarXUserId, token);
  },
);

const psychologistProfileRequestSlice = createSlice({
  name: "profileChangeRequests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfileChangeRequests.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfileChangeRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.requests = action.payload;
      })
      .addCase(getProfileChangeRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(approveProfileChangeRequest.pending, (state) => {
        state.approving = true;
      })
      .addCase(approveProfileChangeRequest.fulfilled, (state, action) => {
        state.approving = false;
        state.requests = state.requests.filter(
          (request) => request._id !== action.meta.arg,
        );
      })
      .addCase(approveProfileChangeRequest.rejected, (state, action) => {
        state.approving = false;
        state.error = action.payload as string;
      });
  },
});

export default psychologistProfileRequestSlice.reducer;
