import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const LoadingScreen: React.FC = () => {
  return (
    <div className="flex h-auto min-h-[60vh] items-center justify-center bg-[#171616]">
      <ClipLoader color={"#d6d6d6"} size={50} />
    </div>
  );
};

export default LoadingScreen;
