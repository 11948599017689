import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import {
  fetchCalendarProfile,
  saveCalendarProfile,
  updateCalendarProfile,
  uploadProfileImage,
  clearProfileState,
} from "../../redux/slices/CalendarProfileSlice";
import profileDefaultImage from "../../assets/profile-default.png";
import { toast } from "react-hot-toast";
import LoadingScreen from "../LoadingScreen";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const CalendarProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [loadingData, setLoadingData] = useState(true);
  const {
    fullName: initialFullName,
    email: initialEmail,
    about: initialAbout,
    location: initialLocation,
    image,
    loading,
  } = useSelector((state: RootState) => state.calendarProfile);
  const [profileImage, setProfileImage] = useState<string>(
    sessionStorage.getItem("profileImage") || profileDefaultImage,
  );
  const [currentFullName, setCurrentFullName] = useState(initialFullName);
  const [currentEmail, setCurrentEmail] = useState(initialEmail);
  const [currentAbout, setCurrentAbout] = useState(initialAbout);
  const [currentLocation, setCurrentLocation] = useState(initialLocation);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const charLimit = 300;

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [currentAbout]);

  useEffect(() => {
    dispatch(fetchCalendarProfile()).then((action) => {
      if (fetchCalendarProfile.fulfilled.match(action)) {
        const profileData = action.payload;
        setIsFirstTime(!profileData.fullName);
      }
      setLoadingData(false);
    });
    return () => {
      dispatch(clearProfileState());
    };
  }, [dispatch]);

  useEffect(() => {
    setCurrentFullName(initialFullName);
    setCurrentEmail(initialEmail);
    setCurrentAbout(initialAbout);
    setCurrentLocation(initialLocation);
    const profileImg = image
      ? `${process.env.REACT_APP_S3_URL}/uploads/calendar-x/profile/${image}`
      : profileDefaultImage;
    setProfileImage(profileImg);
    sessionStorage.setItem("profileImage", profileImg);
  }, [initialFullName, initialEmail, initialAbout, initialLocation, image]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= charLimit) {
      setCurrentAbout(e.target.value);
    }
  };

  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      setIsUploadingImage(true);
      toast.loading("Uploading image...");
      dispatch(uploadProfileImage(file)).then((action) => {
        if (uploadProfileImage.fulfilled.match(action)) {
          const profileImageUrl = `${process.env.REACT_APP_S3_URL}/uploads/calendar-x/profile/${action.payload}`;
          sessionStorage.setItem("profileImage", profileImageUrl);
          sessionStorage.setItem("profileImageFileName", action.payload);
          setProfileImage(profileImageUrl);
          toast.dismiss();
          toast.success("Image uploaded successfully!");

          dispatch(
            updateCalendarProfile({
              fullName: currentFullName,
              email: currentEmail,
              about: currentAbout,
              location: currentLocation,
              image: action.payload,
            }),
          ).then((resultAction) => {
            setIsUploadingImage(false);
            if (updateCalendarProfile.fulfilled.match(resultAction)) {
              console.log("Profile updated with new image");
            } else {
              console.log("Failed to update profile with new image");
            }
          });

          console.log("Profile image uploaded");
        } else {
          setIsUploadingImage(false);
          toast.dismiss();
          toast.error("Failed to upload profile image");
          console.log("Failed to upload profile image");
        }
      });
    }
  };

  const handleSave = async () => {
    const profileImageFileName =
      sessionStorage.getItem("profileImageFileName") || image || "";

    const actionCreator = isFirstTime
      ? saveCalendarProfile
      : updateCalendarProfile;
    const resultAction = await dispatch(
      actionCreator({
        fullName: currentFullName,
        email: currentEmail,
        about: currentAbout,
        location: currentLocation,
        image: profileImageFileName,
      }),
    );

    console.log("Save result:", resultAction);
    if (actionCreator.fulfilled.match(resultAction)) {
      toast.success(
        isFirstTime
          ? "Profile created successfully!"
          : "Profile updated successfully!",
      );
      setIsFirstTime(false);
    } else {
      toast.error("Oops! Something went wrong.");
    }
  };

  if (loadingData) {
    return (
      <p className="mt-32">
        <LoadingScreen />
      </p>
    );
  }

  return (
    <div>
      <div className="m-auto my-10 w-[60%] rounded-xl border border-primary">
        <div>
          <div className="flex items-center gap-x-3 px-6 pt-5 text-white text-opacity-90">
            <button onClick={() => navigate(-1)} className="text-lg">
              <FaArrowLeft />
            </button>
            <p className="text-2xl font-semibold">Calendar-X Profile</p>
          </div>
          <p className="mt-2 border-b border-primary px-6 pb-5 font-medium text-light text-opacity-45">
            Manage your Calendar-x Profile here
          </p>
        </div>
        <div className="mb-4 mt-8 flex flex-col items-center">
          <div className="relative">
            <img
              src={profileImage}
              alt="Profile"
              className="h-32 w-32 rounded-full border-2 border-primary object-cover"
            />
            <input
              type="file"
              id="profileImage"
              onChange={handleProfileImageChange}
              className="hidden"
              ref={fileInputRef}
            />
          </div>
          <button
            onClick={() => fileInputRef.current?.click()}
            className="mt-6 flex items-center rounded bg-[#fbfafb] px-4 py-2 text-[15px] font-semibold text-primary hover:opacity-80"
          >
            {isFirstTime
              ? "Upload Profile Picture"
              : "Upload New Profile Picture"}
          </button>
        </div>
        <div className=" px-10">
          <div className="mb-4">
            <label className="mb-2 block font-semibold">
              Full Name <span className="text-red-500"> *</span>
            </label>
            <input
              type="text"
              value={currentFullName}
              placeholder="John Doe"
              onChange={(e) => setCurrentFullName(e.target.value)}
              className="mb-2 w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-placeholder focus:border-[#535353] focus:outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-semibold">
              Email <span className="text-red-500"> *</span>
            </label>
            <input
              type="email"
              value={currentEmail}
              placeholder="example@domain.com"
              onChange={(e) => setCurrentEmail(e.target.value)}
              className="mb-2 w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-placeholder focus:border-[#535353] focus:outline-none"
            />
          </div>
          <div className="mb-4">
            <div className="flex items-center justify-between">
              <label className="mb-2 block font-semibold">
                Bio <span className="text-red-500"> *</span>
              </label>
              <div className="mt-2 text-right text-sm text-light text-opacity-80">
                {currentAbout.length}/{charLimit}
              </div>
            </div>
            <textarea
              ref={textareaRef}
              value={currentAbout}
              onChange={handleInputChange}
              placeholder="Write a brief description about yourself"
              className="scrollbar mb-2 h-auto min-h-20 w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-placeholder focus:border-[#535353] focus:outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-semibold">
              Location <span className="text-red-500"> *</span>
            </label>
            <input
              type="text"
              value={currentLocation}
              onChange={(e) => setCurrentLocation(e.target.value)}
              placeholder="Bengaluru, IN"
              className="w-full rounded-md border border-primary bg-[#1a1b1b] px-3 py-2 text-light placeholder:text-placeholder focus:border-[#535353] focus:outline-none"
            />
          </div>
          <div className="flex justify-end space-x-4">
            <button
              onClick={handleSave}
              className="mb-8 mt-4 flex items-center rounded bg-[#fbfafb] px-4 py-2 text-[15px] font-semibold text-primary hover:opacity-80"
              disabled={loading || isUploadingImage}
            >
              {isFirstTime ? "Save" : "Update"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarProfile;
